import { useEffect, useState} from "react";
import { format } from "d3-format";
import {
    CandlestickSeries,
    OHLCTooltip,
    ZoomButtons,
    YAxis,

} from "react-financial-charts";

import {useSelector } from 'react-redux';

const GraphCandlestick = ({chartRef}) => {
    // code to get settings values from redux
  const settings = useSelector((state) => state.settings.settings);
    const [color, setColor] = useState('')
     useEffect(() => {
        setColor(()=> { 
            if(settings.graph.color==='blackAndWhite'){
                return {
                        fill: ((d) => (d.close > d.open ? "rgba(255,255,255, 1)" : "rgba(0,0,0,1)")),
                        stroke:"#OOOOOO",
                        wickStroke:"#OOOOOO",
                        candleStrokeWidth:1,
                        }
                    }
            if(settings.graph.color==='redAndGreen'){
                return {
                        fill: ((d) => (d.close > d.open ? "#26a69a" : "#ef5350")),
                        stroke:"none",
                        wickStroke:((d) => (d.close > d.open ? "#26a69a" : "#ef5350")),
                        candleStrokeWidth:0.5,
                        }

        }
        
    })
    }, [settings]);

    const pricesDisplayFormat = format(".2f");



    // Function to reset zoom
    /* const handleResetZoom =  (direction) => {
            const { xAxisZoom, xScale, plotData, xAccessor } = this.context;
    
            const cx = xScale(xAccessor(last(plotData)));
    
            const { zoomMultiplier } = this.props;
    
            const c = direction > 0 ? 1 * zoomMultiplier : 1 / zoomMultiplier;
    
            const [start, end] = xScale.domain();
            const [newStart, newEnd] = xScale
                .range()
                .map((x) => cx + (x - cx) * c)
                .map(xScale.invert);
    
            const left = interpolateNumber(start, newStart);
            const right = interpolateNumber(end, newEnd);
    
            const foo = [0.25, 0.3, 0.5, 0.6, 0.75, 1].map((i) => {
                return [left(i), right(i)];
            });
    
            this.interval = window.setInterval(() => {
                xAxisZoom(foo.shift());
                if (foo.length === 0) {
                    clearInterval(this.interval);
                    delete this.interval;
                }
            }, 10);
        
    }; */
    return (
        <>
                
                <YAxis showGridLines tickFormat={pricesDisplayFormat} strokeStyle='var(--bs-body-color)'  tickLabelFill='#949191' tickStrokeStyle="#949191"/>
        {color && <CandlestickSeries fill={color.fill} stroke = {color.stroke}
                wickStroke={color.wickStroke} candleStrokeWidth={color.candleStrokeWidth}/>}
                <OHLCTooltip origin={[8, 16]} textFill={'var(--bs-body-color)'}/>
                <ZoomButtons
                    
                    /* onReset={handleResetZoom(1)} */
                />
                </>
    );
};
export default GraphCandlestick;