export const wikiHeadersEs = 
{
    offcanvasTitle:'Contenido',
    indicators:'Indicadores Técnicos',
    ma:'Medias Móviles',
    bb:'Bandas de Bollinger',
    rsi:'RSI - Índice de Fuerza Relativa',
    macd:'MACD - Convergencia y Divergencia de Medias Móviles',
    sar:'SAR Parabólico',
    sto:'Oscilador Estocástico',

    strategies:'Estrategias',
    bollinger_rsi:'Bollinger + RSI',
    moving_average_intersection:'Intersección de Medias Móviles',
    macd_ema:'MACD + EMA',
    sar_macd:'SAR Parabólico + MACD',
    stochastic_rsi:'Oscilador Estocástico + RSI',
    };