export const positionColor = {
    long:'#fb8500',
    short:'#8338ec',
    lightLong:'#FFE2C2',
    lightShort:'#DCC7FA'
}
export const customColorsVariable ={
    primary:'#e48fd4',
    secondary:'#90e4cd',
    //$success:#eafaf6;
    success:'#2BB693',
    danger:'#e37777',
    pro:'#E0B700',
    premium:'#A1A1A1',
    yellow:'#f0c136'
}