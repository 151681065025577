export const wikiHeadersEn = 
{
offcanvasTitle:'Content',
indicators:'Technical Indicators',
ma:'Moving Averages',
bb:'Bollinger Bands',
rsi:'RSI - Relative Strength Index',
macd:'MACD - Moving Average Convergence Divergence',
sar:'Parabolic SAR',
sto:'Stochastic Oscilator',

strategies:'Strategies',
bollinger_rsi:'Bollinger + RSI Strategy',
moving_average_intersection:'Moving Average Intersection',
macd_ema:'MACD + EMA',
sar_macd:'Parabolic SAR + MACD',
stochastic_rsi:'Stochastic Oscillator + RSI Strategy',
};