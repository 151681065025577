import { createSlice } from '@reduxjs/toolkit';


// function to get user from local storage and control that the session is not expired
function getUser(){
  const stored= JSON.parse(localStorage.getItem('user'));
    if(stored){
      if(stored.stats){
        if(stored.stats.lastConnection){
          if (new Date().getTime() - new Date(stored.stats.lastConnection.replace(/^"(.*)"$/, '$1')).getTime() > 60*60*1000) {
            return null;
          } else {
            return stored;
          }
        }
      }
      
    }
    return null;
  }


const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: getUser(),
  },
  reducers: {
    setUser: {
      reducer: (state, action) => {
        state.user = action.payload;
        localStorage.setItem("user", JSON.stringify(action.payload));
      }
    },
  },
});


export const { setUser } = userSlice.actions;
export default userSlice.reducer;

