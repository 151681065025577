import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {translationEn} from "./translationEn"
import {translationEs} from "./translationEs"


i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources:{
        en: {
            translation: translationEn,

        },
        es: {
            translation: translationEs,
        },
    }
  });
  
export default i18n;