import React from 'react';
import { format } from "d3-format";
import {
    sar,
    macd,
    discontinuousTimeScaleProviderBuilder,
    Chart,
    ChartCanvas,
    BarSeries,
    SARSeries,
    SingleValueTooltip,
    MACDSeries,
    MACDTooltip,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    EdgeIndicator,
    MouseCoordinateY,
    MouseCoordinateX
} from "react-financial-charts";
import GraphCandlestick from './GraphCandlestick.js';
import GraphAnnotations from './annotations/GraphAnnotations';
//import adjustAnnotations from './annotations/adjustAnnotations';

const GraphSARMACD = ({strategy, stock_data, params, graph_width, annotations}) => {
    // necesary to put the MACD tooltip
    const macdAppearance = {
        fillStyle: {
            divergence: "#4682B4",
        },
        strokeStyle: {
            macd: "#0093FF",
            signal: "#D84315",
            zero: "rgba(0, 0, 0, 0.3)",
        },
    };
    const ScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
        (d) => new Date(d.date)
    );
    let annotationHeight = 0;
    if (annotations){
        annotationHeight = 80;
    }
    const height = 300 + annotationHeight;
    const width = graph_width;
    const margin = { left: 0, right: 60, top: 0, bottom: 20};
    const macd1 = macd()
        .id(1)
        .options({ long: params.long_window ,
                   short:params.short_window,
                   signal:params.signal_window})
        .merge((d, c) => {
            d.macd1 = c;
        })
        .accessor((d) => d.macd1);
    const sar1 = sar()
        .id(2)
        .options({accelerationFactor: params.sar_initial_af,
                  maxAccelerationFactor: params.sar_max_af})
        .merge((d, c) => {
            d.sar1 = c;
        })
        .accessor((d) => d.sar1); 
    
    //this iswhat calculates the curves  what the fuck...
    const calculatedData1 = macd1(sar1(stock_data));
     // workaround for makin sar1 work
     const calculateSAR = (cd, params) => {
        let start_af = params.sar_initial_af;
        let increment = params.sar_initial_af; 
        let max_af = params.sar_max_af;
        let TentativeSAR = [0.0];
        let SAR = [0.0];
        let EP = [0.0];
        let AF = [0.0];
        let Trend = [0.0];
    
        if (cd[1].high > cd[0].high) {
            Trend.push(1);
            SAR.push(cd[0].low);
            EP.push(cd[1].high);
        } else {
            Trend.push(-1);
            SAR.push(cd[0].high);
            EP.push(cd[1].low);
        }
        AF.push(start_af);
    
        for (let i = 2; i < cd.length; i++) {
            let prev_row = cd[i - 1];
            let prev_prev_row = i > 2 ? cd[i - 2] : prev_row;
    
            let prev_sar = SAR[SAR.length - 1];
            let trend = Trend[Trend.length - 1];
            let af = AF[AF.length - 1];
            let ep = EP[EP.length - 1];
            let tentative_sar = TentativeSAR[TentativeSAR.length - 1];
    
            // Initialize values
            Trend.push(null);
            EP.push(null);
            AF.push(null);
            TentativeSAR.push(null);
    
            if (trend < 0) {
                tentative_sar = Math.max(prev_sar + af * (ep - prev_sar), prev_row.high, prev_prev_row.high);
    
                if (tentative_sar < cd[i].high) {
                    Trend[i] = 1;
                    EP[i] = cd[i].high;
                    AF[i] = start_af;
                } else {
                    Trend[i] = trend - 1;
                }
            } else if (trend > 0) {
                tentative_sar = Math.min(prev_sar + af * (ep - prev_sar), prev_row.low, prev_prev_row.low);
    
                if (tentative_sar > cd[i].low) {
                    Trend[i] = -1;
                    EP[i] = cd[i].low;
                    AF[i] = start_af;
                } else {
                    Trend[i] = trend + 1;
                }
            }
            TentativeSAR[i] = tentative_sar;
    
            // Calculate SAR, EP, and AF
            if (Trend[i] < 0) {
                if (Trend[i] === -1) {
                    SAR[i] = Math.max(ep, cd[i].high);
                    EP[i] = cd[i].low;
                } else {
                    SAR[i] = tentative_sar;
                    EP[i] = Math.min(cd[i].low, ep);
                }
            } else if (Trend[i] > 0) {
                if (Trend[i] === 1) {
                    SAR[i] = Math.min(ep, cd[i].low);
                    EP[i] = cd[i].high;
                } else {
                    SAR[i] = tentative_sar;
                    EP[i] = Math.max(cd[i].high, ep);
                }
            }
    
            if (Math.abs(Trend[i]) === 1) {
                AF[i] = start_af;
            } else if (EP[i] === EP[i - 1]) {
                AF[i] = af;
            } else {
                AF[i] = Math.min(max_af, af + increment);
            }
    
            cd[i].sar1 = SAR[i];
        }
        return cd;
    };

    const calculatedData = calculateSAR(calculatedData1, params);
    const { data, xScale, xAccessor, displayXAccessor } = ScaleProvider(
        calculatedData
    );
    
   
   
    const pricesDisplayFormat = format(".2f");
    const max = xAccessor(data[data.length - 1]);
    const min = xAccessor(data[Math.max(0, data.length - 20)]);//this controls the initial days viewed but it does not work as it think it should
    const xExtents =[min, max+5];
    const gridHeight = height - margin.top - margin.bottom;
    const barChartHeight = gridHeight / 4;
    const macdHeight = 80;
    const barChartOrigin = (_, h) => [0, h - barChartHeight-macdHeight- annotationHeight];
    const chartHeight = gridHeight - macdHeight- annotationHeight;
    const macdChartOrigin = [0, gridHeight-macdHeight- annotationHeight];
    const barChartExtents = (data) => {
        return data.volume;
    };

    const candleChartExtents = (data) => {
        return [data.high, data.low];
    };


    const yEdgeIndicator = (data) => {
        return data.close;
    };

    const volumeColor = (data) => {
        return data.close > data.open
            ? "rgba(38, 166, 154, 0.3)"
            : "rgba(239, 83, 80, 0.3)";
    };

    const volumeSeries = (data) => {
        return data.volume;
    }; 

    const openCloseColor = (data) => {
        return data.close > data.open ? "#26a69a" : "#ef5350";
    };
    // code for anotations
    /* const [scale, setScale] = useState();
    const [adjustedAnnotations, setAdjustedAnnotations] = useState(null);
    useEffect(() => {
        if (annotations!=null){
            let r =adjustAnnotations(annotations);
            setAdjustedAnnotations(r.adjustedAnnotations);
            setScale(r.scale)
        }
      }, [annotations]);   */
    return (
        <ChartCanvas
            height={height}
            ratio={3}
            width={width}
            margin={margin}
            data={data}
            displayXAccessor={displayXAccessor}
            seriesName="Data"
            xScale={xScale}
            xAccessor={xAccessor}
            xExtents={xExtents}
            zoomAnchor={lastVisibleItemBasedZoomAnchor}
        >
            <Chart
                id={2}
                height={barChartHeight}
                origin={barChartOrigin}
                yExtents={barChartExtents}
            >
                <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries} />
            </Chart> 
            <Chart id={1} height={chartHeight} yExtents={candleChartExtents}>
                <GraphCandlestick/>
                <SARSeries yAccessor={sar1.accessor()} strokeStyle={sar1.stroke()} />
                <MouseCoordinateY
                    rectWidth={margin.right}
                    displayFormat={pricesDisplayFormat}
                />
                <EdgeIndicator
                    itemType="last"
                    rectWidth={margin.right}
                    fill={openCloseColor}
                    lineStroke={openCloseColor}
                    displayFormat={pricesDisplayFormat}
                    yAccessor={yEdgeIndicator}
                />
                <SingleValueTooltip
                        yLabel={`SAR (${sar1.options().accelerationFactor}, ${sar1.options().maxAccelerationFactor})`}
                        yAccessor={sar1.accessor()}
                        origin={[8, 26]}
                        valueFill='var(--bs-body-color)'
                    />

            </Chart>
            <Chart id={3} yExtents={macd1.accessor()} height={macdHeight} origin={macdChartOrigin}>
                {/* {Code for the dates and the date tooltip} */}
                <MouseCoordinateX
                at="bottom"
                orient="bottom"
                displayFormat={(d) => d.toLocaleDateString()}/>
                <XAxis showGridLines gridLinesStrokeStyle="#e0e3eb" strokeStyle='var(--bs-body-color)' tickLabelFill='#949191' tickStrokeStyle="#949191"/>
                <YAxis  tickLabelFill='#949191' tickStrokeStyle="#949191" strokeStyle='var(--bs-body-color)'/>

                <MACDSeries yAccessor={macd1.accessor()} />

                <MACDTooltip origin={[8, 16]} 
                             yAccessor={macd1.accessor()} 
                             options={macd1.options()} 
                             appearance={macdAppearance}/>
                
            </Chart>
            {(annotations!=null) &&
            <Chart id={5} yExtents={[-0.5,0.5]} height={annotationHeight} origin={[0, gridHeight-annotationHeight]}>
                <GraphAnnotations annotations={annotations}></GraphAnnotations>
            </Chart>}
            <CrossHairCursor />
        </ChartCanvas>
    );
};
export default GraphSARMACD;