import React from 'react';
import { Container, Row, Col, Button} from 'react-bootstrap';
import {initTheme} from '../utils/theme'
import i18n from '../language/i18';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import './Landing.css';
import Footer from '../components/Footer';
import Header from '../components/header/Header';
import { FaLinkedin } from "react-icons/fa6";

const Aboutus = () => {
 // code for initializing the theme
  initTheme()
  // hook for translation
  const { i18n, t } = useTranslation();
  
  // google analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
    return (
    <>
        <Header handleShow={0}></Header>
     
      <Container className="mb-5">
        
        <Row className="mt-3 mt-lg-5 mb-5">
          <Col className='text-center'>
            <span className="titulo resaltarTitulo">
              {t('aboutus.title')}
            </span>            
          </Col>
        </Row>
        <Row className="mt-5 mb-5 px-lg-5">
          <Col>
          <div className='fs-5 fs-lg-3 text-center'>{t('aboutus.intro')}</div>
          </Col>
        </Row>
        <Row className="mt-5 mb-5 m-3 text-center">
          <Col className='border border-3 border-secondary rounded mb-5 m-lg-2 d-flex flex-column justify-content-between'>
            <h3 className="mt-3">{t('aboutus.nameMartin')}</h3>
            <img className= "rounded-circle shadow-sm bg-light m-3 mx-auto border border-3 border-primary" height="250" width="250" src={'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/aboutus/martin.png'}></img>
            <div className="mb-3 fs-5">{t('aboutus.infoMartin')}</div>
            <Button type ="Button" variant="outline-secondary" className="btn p-1  mx-auto mb-3 text-primary fs-5" href="https://www.linkedin.com/in/mguimeracastell/" target="_blank" rel="noreferrer">
                <FaLinkedin className=' h2 m-0' ></FaLinkedin> {t('aboutus.linkedin')}
            </Button>
          </Col>
          <Col className='border border-3 border-primary rounded mb-5 m-lg-2 d-flex flex-column justify-content-between'>
            <h3 className="mt-3">{t('aboutus.nameEstefania')}</h3>
            <img className= "rounded-circle shadow-sm bg-light m-3 mx-auto border border-3 border-secondary" height="250" width="250" src={'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/aboutus/Estafania+Galvan.jpeg'}></img>
            <div className="mb-3 fs-5">{t('aboutus.infoEstefania')}</div>
            <Button type ="Button" variant="outline-secondary" className="btn p-1 mx-auto mb-3 bottom-0 text-primary fs-5" href="https://www.linkedin.com/in/estefania-galvan/" target="_blank" rel="noreferrer">
                <FaLinkedin className=' h2 m-0' ></FaLinkedin> {t('aboutus.linkedin')}
            </Button>
          </Col>
          <Col className=' border border-3 border-secondary rounded mb-5 m-lg-2 d-flex flex-column justify-content-between'>
            <h3 className="mt-3">{t('aboutus.nameMarc')}</h3>
            <img className= "rounded-circle shadow-sm bg-light m-3 mx-auto border border-3 border-primary" height="250" width="250" src={'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/aboutus/fotoMarc.jpeg'}></img>
            <div className="mb-3 fs-5">{t('aboutus.infoMarc')}</div>
            <Button type ="Button" variant="outline-secondary" className="btn p-1 mx-auto mb-3 text-primary fs-5" href="https://www.linkedin.com/in/marc-nieto-aliques-b9b919162" target="_blank" rel="noreferrer">
                <FaLinkedin className=' h2 m-0' ></FaLinkedin> {t('aboutus.linkedin')}
            </Button>
          </Col>
          
        </Row>
        <Row className="mb-5 pb-5 px-lg-5">
          <Col>
          <div className=' fs-5 fs-lg-3 text-center'>{t('aboutus.outtro')}</div>
          </Col>
        </Row>
        <Row className="mb-5 pb-5 px-lg-5">
          <Col>
            <div className=' fs-5 fs-lg-3 text-center'>
              {t('aboutus.contactus1')}
              <span className='text-primary'>hello@atomicstonks.com</span>
              {t('aboutus.contactus2')}
            </div>
          </Col>
        </Row>


      
      </Container>
      <Footer className="mt-5"></Footer>
      </>
    );
  };

export default Aboutus;