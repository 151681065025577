
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
const GraphLoading = ({id, line}) => {
return(
    
    <Container fluid >
        <Row className='square border-bottom pt-1 pb-1'>
            <Col className='text-center h5 mb-0'>
            <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
            </Spinner>
            </Col>
        </Row>
    </Container>
  );

};

export default GraphLoading;