
import Container from 'react-bootstrap/Container';
import logo from '../../assets/logo.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import FormSelect from 'react-bootstrap/esm/FormSelect';
import i18n from '../../language/i18';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { FaBookAtlas} from 'react-icons/fa6'; 
import { GrDocumentTest } from "react-icons/gr"
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { setSettings } from '../../redux/settingsReducer';
import './Header.css'
const Header = ({handleShow}) => {
  // code to get settings values from redux
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.settings);
  const setSettings1 = (res)=>{dispatch(setSettings(res))}
  // handleShow must be the function that activates offcanvas nad is applies to the ofcanvas button, but if it is 0 it would not render the button 
  // so the header can be used in pages where the offcanvas is not needed
    const [language, setLanguage] = useState(settings.language);

    // hook for translation
    const { i18n, t } = useTranslation();
    
    useEffect(() => {
      i18n.changeLanguage(language);
      setSettings1({...settings, language: language})
    }, [language]);
    // google analytics
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

return (

<Navbar className="bg-dark pt-1 pb-1" sticky='top'>

      <Container className='mw-100 d-flex justify-content-between'>
      
      <Nav className="d-flex justify-content-start col">
        <Nav.Item>
          <Nav.Link id="navigate_todaysignals_button" className='text-light p-0 me-2 d-flex' href='/todaysignals' >
            <img className= "mt-lg-0 mb-0 me-2" style={{filter: 'brightness(0) invert(1)'}} width="33" height="33" src={logo} loading="lazy"></img>
              <div className='fw-bold d-none d-lg-flex align-self-center'>{t('header.todaysignals')}</div>
            </Nav.Link>
        </Nav.Item>
        <Nav.Item >
          <Nav.Link id="navigate_ranking_button" className='text-light p-0 mx-2  d-flex' href='/ranking' >
            <GrDocumentTest className='mt-1 mt-lg-0 mb-0 me-2 h3'/>
            <div className='fw-bold d-none d-lg-flex align-self-end'>{t('header.ranking')}</div>
          </Nav.Link>
        </Nav.Item>
        
        <Nav.Item >
          <Nav.Link  id="navigate_wiki_button" className='text-light p-0 mx-2 d-flex' href='/wiki' >
            <FaBookAtlas className='mt-1 mt-lg-0 mb-0 me-2 h3'/>
            <div className='fw-bold d-none d-lg-flex align-self-end'>{t('header.wiki')}</div>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Nav.Link href='/' className= "d-flex col justify-content-center">
      <img className= "mx-1" width="45" height="45" src={logo} loading="lazy"></img>
      <div className='fw-bold d-none d-lg-flex align-self-center m-0 text-light h3'>AtomicStonks</div>
      </Nav.Link>
        <div  className='d-flex me-2 col justify-content-end' >
          <FormSelect bsPrefix='language-select' onChange={(e)=>{setLanguage(e.target.value)}} size="sm" className="bg-dark text-light me-3 border-0 " defaultValue={language}>
            <option value="en">En</option>
            <option value="es">Es</option>
          </FormSelect>
          {handleShow!==0 &&
          <svg id="menu_button" className="" onClick={handleShow} xmlns="http://www.w3.org/2000/svg" type="button" width="30px" height="30px" fill="white" viewBox="0 0 16 16"  >
            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>}
        </div>
        
      </Container>
    </Navbar>

  );

};

export default Header;
