import React from 'react';
import { format } from "d3-format";
import {
    stochasticOscillator,
    rsi,
    discontinuousTimeScaleProviderBuilder,
    Chart,
    ChartCanvas,
    BarSeries,
    StochasticSeries,
    StochasticTooltip,
    RSITooltip,
    RSISeries,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    EdgeIndicator,
    MouseCoordinateX,
    MouseCoordinateY
} from "react-financial-charts";
import GraphCandlestick from './GraphCandlestick';
import GraphAnnotations from './annotations/GraphAnnotations';
//import adjustAnnotations from './annotations/adjustAnnotations';


const GraphStochasticRSI = ({strategy, stock_data, params, graph_width, annotations}) => {
    const ScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
        (d) => new Date(d.date)
    );
    let annotationHeight = 0;
    if (annotations){
        annotationHeight = 80;
    }
    const height = 380 + annotationHeight;
    const width = graph_width;
    const margin = { left: 0, right: 60, top: 0, bottom: 20};
   
    const sto = stochasticOscillator()
        .options({ windowSize: params.k_period, kWindowSize: 1, dWindowSize: params.d_period })
        .merge((d, c) => {
            d.sto = c;
        })
        .accessor((d) => d.sto);
    const rsi1 = rsi()
        .id(2)
        .options({windowSize: params.rsi_period})
        .merge((d, c) => {
            d.rsi1 = c;
        })
        .accessor((d) => d.rsi1); 
    
    //this iswhat calculates the curves  what the fuck...
    const calculatedData = sto(rsi1(stock_data));
    const { data, xScale, xAccessor, displayXAccessor } = ScaleProvider(
        calculatedData
    );

   
   
    const pricesDisplayFormat = format(".2f");
    const max = xAccessor(data[data.length - 1]);
    const min = xAccessor(data[Math.max(0, data.length - 20)]);//this controls the initial days viewed but it does not work as it think it should
    const xExtents =[min, max+5];
    const gridHeight = height - margin.top - margin.bottom;
    const barChartHeight = gridHeight / 4;
    const rsiHeight = 80;
    const stoHeight = 80;
    const barChartOrigin = (_, h) => [0, h - barChartHeight-rsiHeight-stoHeight-annotationHeight];
    
    const chartHeight = gridHeight - rsiHeight- stoHeight-annotationHeight;
    const rsiChartOrigin = [0, gridHeight-rsiHeight-stoHeight-annotationHeight];
    const stoChartOrigin = [0, gridHeight-rsiHeight-annotationHeight];
    const barChartExtents = (data) => {
        return data.volume;
    };

    const candleChartExtents = (data) => {
        return [data.high, data.low];
    };


    const yEdgeIndicator = (data) => {
        return data.close;
    };

    const volumeColor = (data) => {
        return data.close > data.open
            ? "rgba(38, 166, 154, 0.3)"
            : "rgba(239, 83, 80, 0.3)";
    };

    const volumeSeries = (data) => {
        return data.volume;
    }; 

    const openCloseColor = (data) => {
        return data.close > data.open ? "#26a69a" : "#ef5350";
    };
    // code for anotations
/*     const [scale, setScale] = useState();
    const [adjustedAnnotations, setAdjustedAnnotations] = useState(null);
    useEffect(() => {
        if (annotations!=null){
            let r =adjustAnnotations(annotations);
            setAdjustedAnnotations(r.adjustedAnnotations);
            setScale(r.scale)
        }
      }, [annotations]);   */
    return (
        <ChartCanvas
            height={height}
            ratio={3}
            width={width}
            margin={margin} 
            data={data}
            displayXAccessor={displayXAccessor}
            seriesName="Data"
            xScale={xScale}
            xAccessor={xAccessor}
            xExtents={xExtents}
            zoomAnchor={lastVisibleItemBasedZoomAnchor}
        >    
            <Chart
                id={2}
                height={barChartHeight}
                origin={barChartOrigin}
                yExtents={barChartExtents}
            >


                <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries} />
            </Chart> 
            <Chart id={1} height={chartHeight} yExtents={candleChartExtents}>
                <GraphCandlestick/>
                <CrossHairCursor />
                <MouseCoordinateY
                    rectWidth={margin.right}
                    displayFormat={pricesDisplayFormat}
                />
                <EdgeIndicator
                    itemType="last"
                    rectWidth={margin.right}
                    fill={openCloseColor}
                    lineStroke={openCloseColor}
                    displayFormat={pricesDisplayFormat}
                    yAccessor={yEdgeIndicator}
                />
                 
               

            </Chart>
            <Chart id={3} yExtents={rsi1.accessor()} height={rsiHeight} origin={rsiChartOrigin}>
                <YAxis tickValues={[params.threshold_down, 50, params.threshold_up]}  tickLabelFill='#949191' tickStrokeStyle="#949191" strokeStyle='var(--bs-body-color)'/>

                <RSISeries yAccessor={rsi1.accessor()} />

                <RSITooltip origin={[8, 16]} yAccessor={rsi1.accessor()} options={rsi1.options()} textFill='var(--bs-body-color)' />
            </Chart>
            <Chart id={4} yExtents={sto.accessor()} height={stoHeight} origin={stoChartOrigin}>
                {/* {Code for the dates and the date tooltip} */}
                <MouseCoordinateX
                at="bottom"
                orient="bottom"
                displayFormat={(d) => d.toLocaleDateString()}/>
                <XAxis showGridLines gridLinesStrokeStyle="#e0e3eb" strokeStyle='var(--bs-body-color)' tickLabelFill='#949191' tickStrokeStyle="#949191"/>
                <YAxis tickValues={[params.threshold_down, 50, params.threshold_up]}  tickLabelFill='#949191' tickStrokeStyle="#949191" strokeStyle='var(--bs-body-color)'/>

                <StochasticSeries yAccessor={sto.accessor()} />
                <StochasticTooltip
                        origin={[8, 16]}
                        yAccessor={(d) => d.sto}
                        options={sto.options()}
                        appearance={{ stroke: StochasticSeries.defaultProps.strokeStyle }}
                    />
                
            </Chart>
    {(annotations!=null) &&
            <Chart id={5} yExtents={[-0.5,0.5]} height={annotationHeight} origin={[0, gridHeight-annotationHeight]}>
                <GraphAnnotations annotations={annotations}></GraphAnnotations>
            </Chart>}
        </ChartCanvas>
    );
};
export default GraphStochasticRSI;