import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";
import {Bar} from 'react-chartjs-2';
import {Chart, ArcElement, Legend, Tooltip, CategoryScale, LinearScale, BarElement} from 'chart.js'
import { host } from '../../utils/variablesProdTest';
import {useSpring, animated} from 'react-spring';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { positionColor } from '../../utils/colors';
import { useSelector } from 'react-redux';
Chart.register(ArcElement, Legend, Tooltip, ChartDataLabels, CategoryScale, LinearScale, BarElement);

const TodaysignalsFilter = ({selection}) => {
  //get user from redux
  const user = useSelector((state) => state.user.user);
  // hook for translation
  const { i18n, t } = useTranslation();
  //function to order a json by value
  function orderByValue (obj){
    // Convertir el objeto a una matriz de pares clave-valor
    var dataArray = Object.entries(obj);
    // Ordenar la matriz por los valores (segundo elemento de cada par)
    dataArray.sort(function(a, b) {
      return b[1] - a[1];
    });
    let res = [] 
    for (let i=0;i<=dataArray.length/2;i++){
      res.push(dataArray[i]);
      res.push(dataArray[dataArray.length - i - 1]);
    }
    if(dataArray.length % 2 !== 0){
      res.pop()
    }
    // Crear un nuevo objeto a partir de la matriz ordenada
    return Object.fromEntries(res);
  }

  // Function to split texts if are longer than 15 char 
  function splitString(inputString) {
        // Verificar si el string tiene más de 15 caracteres
    if (inputString.length > 15) {
      // Calcular la posición más cercana a la media del string
      var media = Math.floor(inputString.length / 2);
      
      // Buscar el espacio más cercano a la posición media
      var posicionEspacioCercano = inputString.substring(0, media).lastIndexOf(' ');

      // Verificar si se encontró un espacio
      if (posicionEspacioCercano !== -1) {
        // Cambiar el espacio más cercano por '\n'
        var nuevoString = inputString.substring(0, posicionEspacioCercano) + '\n' + inputString.substring(posicionEspacioCercano + 1);
        return nuevoString;
      }else{
        //Si no encontramos el espacio cerca de la mitad pero es más de 15 char cambiamos el primero
        // Buscar todos los espacios en el string
        var espacios = inputString.split(' ');
        // Verificar si hay más de un espacio
        if (espacios.length > 1) {
          // Encontrar el índice del espacio de en medio
          var indiceEspacioEnMedio = Math.floor(espacios.length / 2);

          // Cambiar el espacio de en medio por '\n'
          espacios.splice(indiceEspacioEnMedio, 0, '\n');
    
          // Unir los elementos del array de nuevo en un string
          var nuevoString = espacios.join(' ');
          return nuevoString;
      }
      }
    }
    // Devolver el string original si no se cumple la condición
    return inputString;
    }


  //Default colors
  const colorPosition = { 
    back:{
        short:positionColor.lightShort,
        long:positionColor.lightLong
    },
    border:{
      short:positionColor.short,
      long:positionColor.long
    },
  }
  //colors to iterate i've copied it a buch of times to avoid implementing rotation, just going foward
  const colors ={
    border:["#cf3ab4","#bd4cb2","#a75bae","#8c64a6","#7e67a2","#6c6c9d","#668ea3","#60a9a9","#4cbda8","#3acfa7",
            "#cf3ab4","#bd4cb2","#a75bae","#8c64a6","#7e67a2","#6c6c9d","#668ea3","#60a9a9","#4cbda8","#3acfa7",
            "#cf3ab4","#bd4cb2","#a75bae","#8c64a6","#7e67a2","#6c6c9d","#668ea3","#60a9a9","#4cbda8","#3acfa7",
            "#cf3ab4","#bd4cb2","#a75bae","#8c64a6","#7e67a2","#6c6c9d","#668ea3","#60a9a9","#4cbda8","#3acfa7",
            "#cf3ab4","#bd4cb2","#a75bae","#8c64a6","#7e67a2","#6c6c9d","#668ea3","#60a9a9","#4cbda8","#3acfa7"],
    back:["#e48fd4","#da9ad4","#cfa5d3","#c5b0d2","#c0b5d2","#babad1","#b0c5d0","#a5cfcf","#9bdace","#90e4cd",
          "#e48fd4","#da9ad4","#cfa5d3","#c5b0d2","#c0b5d2","#babad1","#b0c5d0","#a5cfcf","#9bdace","#90e4cd",
          "#e48fd4","#da9ad4","#cfa5d3","#c5b0d2","#c0b5d2","#babad1","#b0c5d0","#a5cfcf","#9bdace","#90e4cd",
          "#e48fd4","#da9ad4","#cfa5d3","#c5b0d2","#c0b5d2","#babad1","#b0c5d0","#a5cfcf","#9bdace","#90e4cd",
          "#e48fd4","#da9ad4","#cfa5d3","#c5b0d2","#c0b5d2","#babad1","#b0c5d0","#a5cfcf","#9bdace","#90e4cd"]
  }
  //standar properties that have to go in the dataset because reasons
  const stdDataSet ={
    borderWidth: 3,
    borderRadius: {
      topLeft:5,
      topRight:5,
      bottomLeft:5,
      bottomRight :5,
    },
    borderSkipped:false,
    barThickness:75,
  };
  // initial data for pie chart
  const defaultGraph =  {
    labels:[''],
    datasets: [
      { ...stdDataSet,
        label: '',
        data: [100], 
        backgroundColor: '#90E4CD',
        
      },
      
    ],
  }
  const [textsize, setTextsize] = useState(12);
  const [allInfo, setAllInfo] = useState(null);
  const [strategy, setStrategy] = useState({...defaultGraph});
  const [country, setCountry] = useState({...defaultGraph});
  const [position, setPosition] = useState({...defaultGraph});
  const [sector, setSector] = useState({...defaultGraph});
  const [count, setCount] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);
  const [once, setOnce] = useState(true);

  const fetchStatsGraph = () =>   {
    axios.post(host+ "statsGraph.php",
      {
        subscription:user.subscription
      }
    ).then(data => {
      setAllInfo(data.data)
    }).catch((error) => {
      // Error
      setAllInfo(null);
      if (error.response) {
          
      } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
      } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
      }
      console.log(error.config);
  })  
  }
  // to get the initial table and set the text size
  useEffect (()=>{
    fetchStatsGraph();
    setTextsize(()=>{
      if(window.innerWidth<=576){
        return 9;
      }else{
        return 12;
      }
    })
  }, []);

  
  // to convert the table in the info of the graph
 // Función para acumular información por cada eje
function accumulateByAxis(acc, item) {
  const checkIndexes = (itemIndexes, selectionIndexes) => {
    if (!itemIndexes) return false;
    // Remove braces and split by comma
    const indexesArray = itemIndexes.replace(/{|}/g, '').split(',').map(index => index.trim());
    return indexesArray.some(index => selectionIndexes[index]);
  };
  // Si selection es null o si viene vacio porque no se ha llegado a recibir del server, acumula sin aplicar filtros
  
  if (!selection || (Object.keys(selection).length === 0 && selection.constructor === Object)) {
    // Acumular información por sector
    acc.sector[item.sector] = (acc.sector[item.sector] || 0) + item.total;
    // Acumular información por country
    acc.country[item.country] = (acc.country[item.country] || 0) + item.total;
    // Acumular información por position
    const positionType = item.position > 0 ? 'long' : 'short';
    acc.position[positionType] = (acc.position[positionType] || 0) + item.total;
    // Acumular información por strategy
    acc.strategy[item.strategy] = (acc.strategy[item.strategy] || 0) + item.total;
  }
  // Si selection no es null, aplicar filtros
  
  else if (
    
    selection.sector[item.sector] &&
    selection.country[item.country] &&
    selection.position[item.position > 0 ? 'long' : 'short'] &&
    selection.strategy[item.strategy] &&
    selection.industry[item.industry] &&
    selection.exchange[item.exchange] &&
    checkIndexes(item.indexes, selection.indexes)
  ) {
    
    // Acumular información por sector
    acc.sector[item.sector] = (acc.sector[item.sector] || 0) + item.total;
    // Acumular información por country
    acc.country[item.country] = (acc.country[item.country] || 0) + item.total;
    // Acumular información por position
    const positionType = item.position > 0 ? 'long' : 'short';
    acc.position[positionType] = (acc.position[positionType] || 0) + item.total;
    // Acumular información por strategy
    acc.strategy[item.strategy] = (acc.strategy[item.strategy] || 0) + item.total;
  }
  return acc;
}
// this function recives the data to put in a graph as a dict label: value and transforms it to the 
// datasets specifics that the stack bar needs. Also needs the translation section where it is
function fillGraph(data, translationDomain, colorAdvance) {
  let axis = orderByValue(data);
  let datasets = [];
  let counter = 0;
  let c = 0;
  
   for (let key in axis){
    datasets.push({
      ...stdDataSet,
      label:t(translationDomain+'.'+key),
      data:[axis[key]],
      backgroundColor:colors.back[c],
      borderColor:colors.border[c],
    });
    c = c + colorAdvance;
    counter = axis[key]+counter;
  }
  return [datasets, counter]
  
}
function fillGraphPosition(data, translationDomain) {
  let axis = orderByValue(data);
  let datasets = [];
  let counter = 0;
  let c = 0;
  
   for (let key in axis){
    datasets.push({
      ...stdDataSet,
      label:t(translationDomain+'.'+key),
      data:[axis[key]],
      backgroundColor:colorPosition.back[key],
      borderColor:colorPosition.border[key],
    });
    c = c + 3;
    counter = axis[key]+counter;
  }
  return [datasets, counter]
  
}

// Use effect to detect change in selection and re do graph
  useEffect(()=>{
    if (allInfo != null){ 
      let accumulatedData = allInfo.reduce(accumulateByAxis, {
        sector: {},
        country: {},
        position: {},
        strategy: {}
      });
      let translationDomain = null;
      if ((Object.keys(accumulatedData.strategy).length === 0 && accumulatedData.strategy.constructor === Object)||
          (Object.keys(accumulatedData.country).length === 0 && accumulatedData.country.constructor === Object)||
          (Object.keys(accumulatedData.position).length === 0 && accumulatedData.position.constructor === Object)||
          (Object.keys(accumulatedData.sector).length === 0 && accumulatedData.sector.constructor === Object)){
        accumulatedData = {
          sector: {nodata:0.001},
          country: {nodata:0.001},
          position: {nodata:0.001},
          strategy: {nodata:0.001}
        };
        translationDomain = 'filterGraph';
      }
     
      const strat = fillGraph(accumulatedData.strategy, translationDomain !== null ? translationDomain : 'data.strategy',3); 
      // first time we get the count to have the number with all the data
      if (once) {
        setOnce(false);
        setCount(strat[1])
      }
      // we update the number of selected signals
      setSelectedCount(strat[1]);
      setStrategy((prev)=>{return{
        ...prev, 
        datasets:strat[0]
        }
      });
      setCountry((prev)=>{return{
        ...prev, 
        datasets:fillGraph(accumulatedData.country, translationDomain !== null ? translationDomain : 'data.country',1)[0]
        }
      });
      setSector((prev)=>{return{
        ...prev, 
        datasets:fillGraph(accumulatedData.sector, translationDomain !== null ? translationDomain : 'data.sector',1)[0]
        }
      });
      setPosition((prev)=>{return{
        ...prev, 
        datasets:fillGraphPosition(accumulatedData.position, translationDomain !== null ? translationDomain : 'data.position')[0]
        }
      });
    }
  }, [selection, allInfo]);

  // this allow to make the number animation
  const Number = ({ n }) => {
    const { number } = useSpring({
      from: { number: 0 },
      to: { number: n },
      delay: 300,
      config: { mass: 1, tension: 20, friction: 10 },
    });
  
    const animatedNumber = number.to((value) => value.toFixed(0));
  
    return <animated.span>{animatedNumber}</animated.span>;
  };

  
// this are the options of the graphs and the custum plugins for text
  const options={
    //indexAxis:'y',
    layout:{
      padding:10,
    },
    plugins: {
      legend: {
        display: false, // Mostrar leyenda
        position:'top'
      },
      datalabels: {
        display: true,
        borderRadius:5,
        align:(ctx) => {
          // Grab the backgroundColor for this value
          if (ctx.datasetIndex % 2 === 0) {
            return 'right';
          } else {
            return 'left';
          }
        },
        offset:5,
        clip: false,
        rotation: 0,
        formatter: (val, ctx) => {
          // Grab the label for this value
          const label = splitString(ctx.chart.data.datasets[ctx.datasetIndex].label);
          // Put them together
          return `${label}`;
        },
        color: 'white',
        font:{weight:'bolder', 
              size: textsize
              },
        textAlign:'center',
        backgroundColor:(ctx) => {
          // Grab the backgroundColor for this value
          return ctx.chart.data.datasets[ctx.datasetIndex].borderColor;
        },
      },
    }, 
    scales: {
      x: {
        display:false,
        stacked: true,
      },
      y: {
        display:false,
        stacked: true,
      },
      }, 
    maintainAspectRatio:false,
    onClick:(e, ctx)=>{}
  };

  // Estado para gestionar el filtro de la lista
  const [selectedCategory, setSelectedCategory] = useState(null);
  // Función para manejar el clic en una categoría
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  return (
    <Container className='mt-5'>
      <Row>
        <Col className='d-flex align-items-center justify-content-center'>
          <h5>
          <animated.div className='text-center'>
            {t('filterGraph.generated1')}&nbsp;
            <Number n={selectedCount} />
            &nbsp;{t('filterGraph.generated2')}&nbsp;
            <Number n={count} />
          </animated.div>
          </h5>
        </Col>
      </Row>
      <Row >
        <Col lg='3' xs='6'  className='text-center'>
          <h4 className='mt-2 mb-0'>{t('tablah.strategy')}</h4>
          <div style={{height:'40vh'}}>
            <Bar
              data={strategy}
              options={options}
              
            />
          </div>
        </Col>
        <Col lg='3' xs='6'className='text-center'>
        <h4 className='mt-2 mb-0'>{t('tablah.country')}</h4>
        <div style={{height:'40vh'}}>
          <Bar
            data={country}
            options={options}
            onClick={(e) => {
                  console.log(e)
            }}
          />
        </div>
        </Col>
        <Col lg='3' xs='6' className='text-center'>
        <h4 className='mt-2 mb-0'>{t('tablah.sector')}</h4>
        <div style={{height:'40vh'}}>
          <Bar
            data={sector}
            options={options}
            onClick={(e) => {
                  console.log(e)
            }}
          />
        </div>
        </Col>
        <Col lg='3' xs='6' className='text-center'>
        <h4 className='mt-2 mb-0'>{t('tablah.position')}</h4>
        <div style={{height:'40vh'}}>
          <Bar
            data={position}
            options={options}
            onClick={(e) => {
                  console.log(e)
            }}
          />
        </div>
        </Col>
      </Row>
      
      
    </Container>
  );
};

export default TodaysignalsFilter;