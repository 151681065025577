import { Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './SubscribeOnScreen.css'
const SubscribeOnScreen = () => {
    // hook for translation
    const { i18n, t } = useTranslation();
    //function to navigate
    const navigate = useNavigate();
    const handleShow = () => {
            navigate('/pricing')
        }
return (<>
        <div style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            margin:20,
            zIndex: 999 // Ensure it's above other content
        }}>
            <div onClick={handleShow} className=" fw-bold singInButton buttonOnScreenContainer" >
            <Button className="buttonOnScreen">{t('subscribeOnScreen')}</Button>
            </div>
        </div>
   
   
    </>

  );

};

export default SubscribeOnScreen;