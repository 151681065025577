import { useTranslation } from 'react-i18next';
import './AlertTopBar.css'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { host } from '../../utils/variablesProdTest';
import CloseButton from 'react-bootstrap/CloseButton';
const AlertTopBar = ({line, annotations, showInfo, setShowInfo}) => {
    //get user from redux
    const user = useSelector((state) => state.user.user);
    // hook for translation
    const { i18n, t } = useTranslation();
    // this is the number formatter
    const userLanguage = navigator.language || navigator.userLanguage;
    const [show, setShow] = useState(false);
    const [totalSignals, setTotalSignals] = useState(0);
    const fetchTotalSignals= () =>   {
        axios.post(host+ "statsGraph.php",
            {
                subscription : 'pro',
            }
        ).then(data => {
            let totalSum = 0;

            data.data.forEach(item => {
                totalSum += item.total;
            });
            setTotalSignals(totalSum)
        }).catch((error) => {
          // Error
          setTotalSignals(null);
          if (error.response) {
              
          } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the 
              // browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
          } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
          }
          console.log(error.config);
      })  
      }
    useEffect (()=>{
        if (user) {
            setShow(user && user.subscription==='free' ? true : false)
        }
        
        fetchTotalSignals();
    }, []);
return(
    <>
    { show &&  
        <div className="notification-top-bar d-flex align-items-center text-center">
            <p className='ms-auto'>{totalSignals} {t('alertTopBar.signalsGenerated')}
                    <a href='/pricing'>
                    {t('alertTopBar.subscribe')}
                    </a>
                    {t('alertTopBar.toUnlock')}
            </p>
            <CloseButton className="border-0 ms-3 ms-lg-auto me-3 " onClick={()=>(setShow(false))}></CloseButton>
        </div> 
    }
    </>
  );

};

export default AlertTopBar;