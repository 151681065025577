import { useTranslation } from "react-i18next";
import Markdown from 'react-markdown'
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math'
import rehypeRaw from "rehype-raw";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import 'katex/dist/katex.min.css'; // Import Katex styles
import './WikiPages.css'


export const ComponentMarkdown = ({markdownContent}) => {
  // hook for translation
  const { i18n, t } = useTranslation();
  const settings = useSelector((state) => state.settings.settings);
  // i do not know why this is necessary i think is because is not working properly with the outlet in wiki
  useEffect(() => {
    i18n.changeLanguage(settings.language);
  }, []);

   // Custom render function for images
  const renderImage = (props) => {
    console.log(props.src)
    if (props.src && props.src.includes('memes')) {
      console.log(props.src)
      // Check if the image src includes '.meme', if so, add the class 'meme'
      return <img {...props} className="meme" />;
    }
    return <img {...props} />;
  };
  return (
    <div>
      <Markdown className="" remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex, rehypeRaw]} >
        {t(markdownContent)}
      </Markdown>
      <Markdown className="img-width" remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex, rehypeRaw]}>
      {t('wiki.wikiFooter')}
      </Markdown>
    </div>
    
  );
};
