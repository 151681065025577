import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import { useTranslation } from 'react-i18next';
import './TableOperationsRanking.css'

import { positionColor} from '../../utils/colors';
const TableOperationsRanking = ({line, annotations, showOperationsTable}) => {
    // hook for translation
    const { i18n, t } = useTranslation();
    // this is the number formatter
    const userLanguage = navigator.language || navigator.userLanguage;
    const formatter = new Intl.NumberFormat(userLanguage, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        });


    const positionItem = (position) =>   {
        let color ='';
        let text ='';
        if(position===-1){
            color = positionColor.short;
            text = 'short'
        }else{
            color = positionColor.long;
            text = 'long'
        }
        return( <div className={'text-white fw-bold px-2 rounded d-inline-block'} style={{'backgroundColor':color}}>
                    {t('data.position.'+text)}
                </div>
                );
 
        
    }
return(
    <Container className='p-0 text-start table-text mb-2'>

        {annotations && <Row className='mt-1'>  
            <Collapse in = {showOperationsTable} unmountOnExit={true} mountOnEnter={true} >
                <div>
                    <Container className=''>
                        <Row>
                            <Col xs={0} lg={1} className='text-start px-0 fw-bold'>
                            </Col>
                            <Col xs={2} lg={1} className='text-start px-0 fw-bold'>
                            {t('graphinfo.date')}
                            </Col>
                            <Col xs={3} lg={2} className='text-start px-0 fw-bold'>
                            {t('graphinfo.price')}
                            </Col>
                            <Col xs={4} lg={6} className='text-start px-0 fw-bold lg-text-start'>
                            {t('graphinfo.exitReason')}
                            </Col>
                            <Col xs={2} lg={1} className='text-start px-0 fw-bold'>
                            {t('tablah.position')}
                            </Col>
                            <Col xs={1} lg={1} className='text-start px-0 fw-bold'>
                            {t('tablah.profit')}
                            </Col>
                        </Row>
                        {annotations.map((op)=>{
                            return(
                                <Row style={{'border-bottom': 'solid #CAC8D0'}}>
                                    <Col xs={0} lg={1} className='d-none d-lg-block'>
                                        <Row className='border-bottom separate-50'>
                                            {t('graphinfo.entry')}
                                        </Row>
                                        <Row className='separate-50'>
                                            {t('graphinfo.exit')}
                                        </Row>  
                                    </Col>
                                    <Col xs={2} lg={1} className='text-start '>
                                        <Row className='border-bottom  date-text d-flex align-items-center separate-50'>
                                            {op.date_entry}
                                        </Row>
                                        <Row className='date-text d-flex align-items-center separate-50'>
                                            {op.type==='end'
                                            ? 
                                            t('graphinfo.dateOpen')
                                            :
                                            op.date_exit}
                                        </Row>
                                    
                                    </Col>
                                    <Col xs={3} lg={2} className='text-start'>
                                        <Row className=' h-100'>
                                            <Col xs={'auto'} >
                                                <Row className='d-flex align-items-center border-bottom separate-50'>
                                                    {formatter.format(op.price_entry)}
                                                </Row>
                                                <Row className='d-flex align-items-center separate-50'>
                                                    {formatter.format(op.price_exit)}
                                                </Row>
                                            </Col>
                                            <Col className='d-flex align-items-center p-0 ps-1'>
                                                <small>{line.currency}</small>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4} lg={6} className='text-start d-flex align-items-center px-0 pe-1'>
                                    {t('graphinfo.opTable.'+op.type)}
                                    </Col>
                                    <Col xs={2} lg={1} className='text-start d-flex align-items-center px-0'>
                                    {positionItem(op.position)}
                                    </Col>
                                    <Col xs={1} lg={1}className={'text-start d-flex align-items-center px-0 fw-bolder fs-lg-5 fs-xs-8 ' + (op.profitability < 0 ? 'text-danger':'text-success')}>
                                    {op.profitability < 0
                                            ? formatter.format(op.profitability*100) + '%'
                                            : '+'+formatter.format(op.profitability*100) + '%'
                                        }
                                    </Col>
                                </Row>
                            )
                        })}
                    </Container> 
                </div>
            </Collapse>
        </Row>  }
    </Container>

  );

};

export default TableOperationsRanking;