import axios from 'axios';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import Graphtable from '../components/todaysignals/Graphtable';
import GraphlineTop from '../components/ranking/GraphlineTop';
import FilterOffcanvas from '../components/offcanvas/FilterOffcanvas';
import Header from '../components/header/Header';
import Footer from '../components/Footer';
import { Form, Container, Row, Col } from 'react-bootstrap';
import {initTheme} from '../utils/theme'
import { host } from '../utils/variablesProdTest';
import RankingCarousel from '../components/ranking/RankingCarousel';
import { useDispatch, useSelector } from 'react-redux';
import SignInModal from '../components/landing/SingInModal';
import SubscribeOnScreen from '../components/subscribeOnScreen/SubscribeOnScreen';
function Ranking() {
  // hook for translation
  const { i18n, t } = useTranslation();
  // code for initializing the theme
  initTheme()
  // code for getting the user from redux
  const user = useSelector((state) => state.user.user);
  // code for updating tables
  const [table, setTable] = useState([]);
  const [totalLines, setTotalLines] = useState(null);
  const [offset, setOffset]=useState(0);
  const volume = 0;
  //explain selection
  const [selection, setSelection] = useState({});
  const [filter, setFilter] = useState(null);
  // code to get settings values from redux
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.settings);
 
  const fetchFilterTable = () =>   {
    axios.post(host+ "filterTableRanking.php"
    ).then(data => {
      
      let parsed = JSON.parse(data.data[0].filter_profitability); //parsing string to json
      let opt ={};
      for (const [column, list] of Object.entries(parsed)) {
        opt[column] = {};
        for(let i in list) {
          if (column!=="day_period"){
            opt[column][list[i]] = true;
         
          }
        }
      }
      // this is for setting manually the order and default state of the day filter
      opt["day_period"] = {}
      opt["day_period"]["7 days"] = false;
      opt["day_period"]["30 days"] = true;
      opt["day_period"]["90 days"] = false;
      opt["day_period"]["180 days"] = false;
      setSelection(opt);
      // repeated logic for setting the filter
      let res = {};
      for (const [column, list] of Object.entries(opt)) {
        let s = [];
        for(const [k, v] of Object.entries(list)) {
          if (v){
            s.push(k);
          }
        }
        res[column] = s;
      }
      setFilter(res);
      })    
  }
  const fetchTable = () =>   {
    axios.post(host+ "graficarRanking.php", 
    
      {
        strategy: filter.strategy, 
        day_period: filter.day_period, 
        volume: volume,
        country: filter.country,
        sector: filter.sector,
        industry: filter.industry,
        exchange: filter.exchange,
        indexes:filter.indexes,
        offset: offset
      }
    ).then(data => {
      setTable(prevTable => [...prevTable, ...data.data]);
      setOffset(prevOffset => prevOffset + 15);
      }
    ).catch((error) => {
        // Error
        setTable([]);
        setOffset(0);
        if (error.response) {
            
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    })
  }

  const fetchRowNumber = () =>   {
    axios.post(host+ "contarRegistrosRanking.php", 
    
      {
        strategy: filter.strategy, 
        day_period: filter.day_period, 
        volume: volume,
        country: filter.country,
        sector: filter.sector,
        industry: filter.industry,
        indexes:filter.indexes,
        exchange: filter.exchange
      }
    ).then(data => {
      setTotalLines(data.data[0].total_rows);
      }).catch((error) => {
        // Error
        setTotalLines(0);
        if (error.response) {
            
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    })  
  }

  // code for fetching the top winners and losers
  const [topWinners, setTopWinners] = useState(null);
  const [topLosers, setTopLoser] = useState(null);
  const fetchTop = () =>   {
    // call for topWinners
    axios.post(host+ "topRanking.php", 
    
      {
        strategy: filter.strategy, 
        day_period: filter.day_period, 
        volume: volume,
        country: filter.country,
        sector: filter.sector,
        industry: filter.industry,
        exchange: filter.exchange,
        indexes:filter.indexes,
        order: 'DESC',
        limit:5
      }
    ).then(data => {
      setTopWinners(data.data);
      }
    ).catch((error) => {
        // Error
        setTopWinners(null);
        if (error.response) {
            
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    })
    // call for toploosers
    axios.post(host+ "topRanking.php", 
    
      {
        strategy: filter.strategy, 
        day_period: filter.day_period, 
        volume: volume,
        country: filter.country,
        sector: filter.sector,
        industry: filter.industry,
        exchange: filter.exchange,
        indexes:filter.indexes,
        order: 'ASC',
        limit:5
      }
    ).then(data => {
      setTopLoser(data.data);
      }
    ).catch((error) => {
        // Error
        setTopLoser(null);
        if (error.response) {
            
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    })
  }
  // code for drawing top
  // revisar esta función en el server directamente
  const top = ()=>{

    return(
      <Container >
        <Row className='pt-1 pb-1'>
            <Col xs={12} lg={6} className='p-2'>
              <div className='border border-success rounded p-1 w-100 h-100'>
                <div className='text-center fs-3 fw-bold text-success'>
                  {t('ranking.topWinners')}
                </div>
                {(topWinners.length > 0) ? topWinners.map(line => {
                return(line.graph && <GraphlineTop id = {1} line = {line} settings={settings}/>)
                }
                
                ) :
                <div style={{color: 'lightgrey'}} className='text-center fs-2 fw-bold h-100 w-100'>
                {t('tablah.nodata')}
                </div>
                }
              </div>
            </Col>

            <Col xs={12} lg={6} className='p-2'>
              <div className='border border-danger rounded p-1 w-100 h-100'>
                <div className='text-center fs-3 fw-bold text-danger'>
                  {t('ranking.topLosers')}
                </div>
                {topLosers.length > 0 ? topLosers.map(line => {
                return(line.graph && <GraphlineTop id = {1} line = {line} settings={settings}/>)
                }) :
                <div style={{color: 'lightgrey'}} className='text-center fs-2 fw-bold h-100 w-100'>
                {t('tablah.nodata')}
                </div>
                }
              </div>
            </Col>
        </Row>
    </Container>
    )
  }

  // this are the use effects that control the filter
  useEffect(() => {
    fetchFilterTable();
  }, []);

  useEffect(() => {
    if (filter!=null){
      fetchRowNumber();
      fetchTable();
      fetchTop();
    }
  }, [filter]);
  
  // this manages the open and close of the offcanvas
  // if it was open and it is closed we filter de selection
  const [open, setOpen] = useState(false);
  const handleShow = () =>   {
    if (open===true){
      filterSelection();
    }
    setOpen(!open);
    
  }
  
  //code for managing the selection done in the filter

  const configureSelection = (column, field, isChecked) =>   {
    if (isChecked){
      setSelection((prev)=>{return{...prev, 
        [column]:{
          ...prev[column],
          [field]:true}}});
    }else{
      setSelection((prev)=>{return{...prev, 
        [column]:{
          ...prev[column],
          [field]:false}}});
    }
  } 
  // code for apliying the filter once is clicked the button
  const filterSelection = () =>   {
    let res = {};
    for (const [column, list] of Object.entries(selection)) {
      let s = [];
      for(const [k, v] of Object.entries(list)) {
          if (v){
            s.push(k);
          }
      }
      res[column] = s;
    }
    setTable([]);
    setOffset(0);
    setTotalLines(0);
    setFilter(res);
    
  }  
 // code for the radio buttons i do not like this garbage of useState with useEffect
 // could fail some times, but i do not know how to do it to work with selection variable only 
 // probably should make other variable
  const [radioClick, setRadioClick ]= useState(false);
  useEffect(() => {
    if (radioClick) {
      setRadioClick(false)
      filterSelection();
    }
  }, [selection]); // Run the effect whenever selection changes

  const onChangeSelection = (e) => {
    setRadioClick(true);
    let res = {};
    Object.keys(selection.day_period).map(k => {
      if (k === e.target.id) {
        res[k] = true;
      } else {
        res[k] = false;
      }
      return res;
    });
    
    setSelection((prev) => ({
      ...prev,
      ['day_period']: res
    }));

    
  };
  return (
  <div>
    
 <Header handleShow={handleShow}></Header> 
 {user!=null && user.subscription==='free' && <SubscribeOnScreen></SubscribeOnScreen>}
 {user===null && 
  <SignInModal show={true} onHide={null} url2navigate={null}></SignInModal>
  }
 <FilterOffcanvas open={open} handleShow={handleShow} filter_options={selection} 
                   configureSelection={configureSelection} filterSelection ={filterSelection}></FilterOffcanvas>
  {filter && 
  <div className='mt-3 mb-3 d-flex flex-wrap justify-content-center'>
        {Object.keys(selection.day_period).map(s => (
            <div  className = 'm-1 p-1 border rounded border-secondary'>
              <Form.Check type="radio" onChange={onChangeSelection}  checked={selection.day_period[s]}  name={'day_period'} id={s} label={t('data.day_period.'+s)}/>
              </div>
        ))}
  </div>
  }
  {topWinners && topLosers && 
  top()}
  
  <RankingCarousel filter={filter} volume={volume}></RankingCarousel>
  <Graphtable table={table} totalLines={totalLines} offset={offset} fetchTable={fetchTable} mode={"ranking"}/>
  <Footer></Footer> 
</div>
  );
}

export default Ranking;
