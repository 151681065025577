import React from 'react';
import ReactDOM from 'react-dom/client';
import './custom.scss'
// Bootstrap CSS
//import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
//import "bootstrap/dist/js/bootstrap.bundle.min";
import './index.css';
import Router from './router/Router';
import reportWebVitals from './reportWebVitals';
import { Suspense } from 'react';
import "./language/i18";
import ReactGA from 'react-ga4';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import store from './redux/store';


const root = ReactDOM.createRoot(document.getElementById('root'));
// traking for google analytics
const TRACKING_ID = "G-EKC9FQSNH4"; // the tracking id from google analytics
ReactGA.initialize(TRACKING_ID);
root.render(
    
    <GoogleOAuthProvider clientId="682266509553-4a8n1hd7f9s97748qajjqbl0loftnuni.apps.googleusercontent.com">
        <Suspense fallback = "Loading">
            <Provider  store={store}>
            <Router /> 
            </Provider>
        </Suspense>
    </GoogleOAuthProvider>
   
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
