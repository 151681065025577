export const sector2industry = 
{
    basic_materials:['steel_aluminum','building_materials','mining','agricultural_inputs','paper_wood_production','chemicals','gold_copper'],
    communication_services:['telecom_services','entertainment','publishing','advertising_agencies','internet_content_information'],
    consumer_cyclical:['packaging_containers','internet_retail','personal_services','retail','gambling_casinos_resorts',
    'furnishings_fixtures_appliances','luxury_goods','residential_construction','travel_services','leisure',
    'restaurants','hotels_lodging','department_stores','textile_manufacturing','auto','clothing_footwear_accessories'],
    consumer_defensive:['grocery_stores','household_personal_products','discount_stores','food_food_distribution_confectioners',
    'farm_products','beverages_alcoholic','education_training_services','food_food_distribution','tobacco','beverages_nonalcoholic'],
    energy:['thermal_coal','oil_gas','uranium'],
    financial_services:['capital_markets','insurance','financial_data_stock_exchanges','asset_management','financial_conglomerates',
    'credit_services','banks'],
    healthcare:['medical_instruments_supplies','healthcare_plans','biotechnology','drugs','health_information_services',
    'medical_care_facilities','pharmaceutical_retailers','medical_distribution','diagnostics_research','medical_devices'],
    industrials:['trucking','industrial_distribution','engineering_construction','airlinesairports_air_services','conglomerates',
    'electrical_equipment_parts','security_protection_services','staffing_employment_services',
    'waste_management_pollution_treatment_controls','data_analytics','rental_leasing_services','tools_accessories',
    'marine_shipping','machinery__industrial_construction_farm_speciality','integrated_freight_logistics','infrastructure_operations',
    'aerospace_defense','business_equipment_supplies','railroads','specialty_business_services','building_products_equipment'],
    real_estate:['real_estate','reit_B2B'],
    technology:['information_technology_services','electronic_components','consumer_electronics','communication_equipment','software',
    'solar','semiconductors','scientific_technical_instruments','computer_hardware'],
    utilities:['utilities_renewable','utilities_diversified','utilities_independent_power_producers','utilities_regulated_water',
    'utilities_regulated_energy']


};