import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faInstagram,
    faTiktok,
    faXTwitter,
    faLinkedin
  } from "@fortawesome/free-brands-svg-icons";
  import logo from '../assets/logo.png';

const Footer = () => {
    // hook for translation
    const { i18n, t } = useTranslation();

return (


  <footer className="bg-dark text-center text-lg-start text-light border border-0 border-top">

    <Container className="p-2">

      <Row className="mt-5">

        <Col lg = {4} xs= {12} className="mb-4 mb-md-0 text-center">

          <div >
            <img className="d-flex align-items-center justify-content-center mb-2 mx-auto" src={logo} height="150" alt=""
                 loading="lazy" />
          </div>
          <div className="mb-2  fs-4 fw-bold">AtomicStonks</div>

          <div className="mb-1 fs-5 fw-bold">{t('footer.description')}</div>
          <div className="mb-1  fs-5 fw-bold"><a href="/aboutus">{t('footer.aboutus')}</a></div>
          
        </Col>

        <Col lg = {8} xs= {12} className="mb-4 mt-5">
        <Row>
            <Col className="align-middle text-center">
          <div className="mb-4 ms-3 fs-3 fw-bold">{t('footer.followus')}</div>
          <div className="mb-4 ms-3 fs-3 fw-bold">{t('footer.followus2')}</div>
            <a
               className="btn text-dark text-center border rounded-2 border-white m-3"
               style={{backgroundColor: "#FFFFFF", width:"50px",height:"50px", paddingLeft:'6px', paddingTop:'8px'}}
               href="https://www.youtube.com/channel/UCY7yzz4qjvsLG6W8ml5hLdQ"
               target="_blank"
               rel="noreferrer"
               role="button"
               >
                <FontAwesomeIcon size='2xl' icon = {faYoutube}></FontAwesomeIcon></a>

            <a
               className="btn text-dark border rounded-2 border-white m-3 p-2"
               style={{backgroundColor: "#FFFFFF", width:"50px",height:"50px" }}
               href="https://twitter.com/atomic_stonks"
               target="_blank"
               rel="noreferrer"
               role="button"
               ><FontAwesomeIcon size='2xl' icon = {faXTwitter}></FontAwesomeIcon></a>


            <a
               className="btn text-dark border rounded-2 border-white m-3 p-2"
               style={{backgroundColor: "#FFFFFF", width:"50px",height:"50px" }}
               href="https://www.instagram.com/atomicstonks"
               target="_blank"
               rel="noreferrer"
               role="button"
               ><FontAwesomeIcon size='2xl' icon = {faInstagram}></FontAwesomeIcon></a>
            <a
               className="btn text-dark border rounded-2 border-white m-3 p-2"
               style={{backgroundColor: "#FFFFFF", width:"50px",height:"50px" }}
               href="https://www.tiktok.com/@atomicstonks"
               target="_blank"
               rel="noreferrer"
               role="button"
               ><FontAwesomeIcon size='2xl' icon = {faTiktok}></FontAwesomeIcon></a>
            <a
               className="btn text-dark border rounded-2 border-white m-3 p-2"
               style={{backgroundColor: "#FFFFFF", width:"50px",height:"50px" }}
               href="https://www.linkedin.com/company/atomicstonks/"
               target="_blank"
               rel="noreferrer"
               role="button"
               ><FontAwesomeIcon size='2xl' icon = {faLinkedin}></FontAwesomeIcon></a>
  {/*           <a
               className="btn text-white border border-white m-3"
               style={{backgroundColor: "#2AABEE"}}
               href="#!"
               target="_blank"
               rel="noreferrer"
               role="button"
               ><FontAwesomeIcon size='2xl' icon = {faTelegram}></FontAwesomeIcon></a>*/}
               </Col> 
            </Row>
            
        </Col>

      </Row>

    </Container>

    
    {/* Copyright*/}
    <div className="text-center p-3">
      <span className='text-primary fs-5 mb-2'>hello@atomicstonks.com</span>
      <p>© 2024 Copyright:&nbsp;
      <a href="/">atomicstonks.com</a></p>
      <h5><a href="https://clearbit.com" target="_blank" rel="noreferrer">Logos provided by Clearbit</a></h5>
    </div>
    {/* Copyright*/}
  </footer>



  );

};

export default Footer;
