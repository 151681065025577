import Container from 'react-bootstrap/Container';
import { Modal, Col, Row } from 'react-bootstrap';
import noLogo from '../../assets/no_logo.png';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './GraphInfo.css'
import { Link } from 'react-router-dom';
const GraphInfo = ({line, annotations, showInfo, setShowInfo}) => {
    //get user from redux
    const user = useSelector((state) => state.user.user);
    // hook for translation
    const { i18n, t } = useTranslation();
    // this is the number formatter
    const userLanguage = navigator.language || navigator.userLanguage;
    const formatter = new Intl.NumberFormat(userLanguage, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        });
    const formatterVolume = new Intl.NumberFormat(userLanguage, {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping:true,
        });
    // function to detect error on image and cahnge to no logo
    const addDefaultImg = (e) =>   {
        e.target.src = noLogo;
    }
    // this transforms the format of the table from column wise to row wise to draw easier
    function transFormTable(data) {
        if (Object.keys(data).length === 0) {
            return null
        }
        const result = {};
        // Get all the keys for the different periods and the percentage change
        const periods = Object.keys(data).filter(key => key !== "Percentage Change");
        const metrics = Object.keys(data[periods[0]]);
        // Loop through each metric
        metrics.forEach(metric => {
            result[metric] = {};
            // Add data for each period
            periods.forEach(period => {
                result[metric][period] = data[period][metric];
            });
            // Add percentage change data
            if (data["Percentage Change"] && data["Percentage Change"].hasOwnProperty(metric)) {
                result[metric]["Percentage Change"] = data["Percentage Change"][metric];
            }
        });

        return result;
    }
    //this recives the original data table and return the keys in order
    function getChronologicalKeys(data) {
        // Extract the keys from the JSON object
        if (Object.keys(data).length === 0) {
            return null
        } 
        const keys = Object.keys(data);
        // Filter out non-date keys (e.g., "Percentage Change")
        const dateKeys = keys.filter(key => /^\d{4}-\d{2}$/.test(key));
        // Sort the date keys in chronological order
        dateKeys.sort((a, b) => new Date(a) - new Date(b));
        return dateKeys;
    }
    const InfoLine=(key, value, table, dates, reverseColor, classPass)=>{
        const color =(percentage, reverseColor)=>{
            if (user && user.subscription==='free'){
                return ''
            }
            if(reverseColor){
                percentage = -percentage
            }
            if(percentage>0){
                return 'bg-success'
            } if (percentage<0) {
                return 'bg-danger'
            } else {
                return ''
            }
        }

        const ProcessIfNull = (all, date, divisor)=>{
            if (all===undefined){
                return '-'
            }if(all[date]===undefined){
                return '-'
            }else{
                return formatter.format(all[date]/divisor)
            }
        }
        return(
            <Row className={classPass}>
                <Col xs={4}>
                {t('graphinfo.'+table+'.'+key)}
                </Col>
                <Col xs={3} className='d-flex flex-row-reverse align-items-center'>
                {user && user.subscription ==='free' ? <div className='w-100 h-100' style={{'backgroundColor':'silver', 'color':'gray'}}></div> : <>{ProcessIfNull(value, dates[1], 1e9)} Bn</>}
                </Col>
                <Col xs={3} className='d-flex flex-row-reverse align-items-center'>
                {user && user.subscription ==='free' ? <div className='w-100 h-100' style={{'backgroundColor':'silver', 'color':'gray'}}></div> : <>{ProcessIfNull(value, dates[0], 1e9)} Bn</>}
                </Col>
                <Col xs={2} className={(value!=null && (color(value["Percentage Change"], reverseColor))) + ' d-flex flex-row-reverse align-items-center'}>
                {user && user.subscription ==='free' ? <div className='w-100 h-100' style={{'backgroundColor':'silver', 'color':'gray'}}></div> : <> {ProcessIfNull(value, "Percentage Change", 1)+'%'}</>}
                </Col>
            </Row>
        )
    };
    const fundamentals= line.fundamentals ? JSON.parse(line.fundamentals) : null;
    const pl = fundamentals && fundamentals.financials ? transFormTable(fundamentals.financials) : null;
    const bs= fundamentals && fundamentals.balance_sheet ? transFormTable(fundamentals.balance_sheet) : null;
    const cf = fundamentals && fundamentals.cashflow ? transFormTable(fundamentals.cashflow) : null;
    const pl_dates=fundamentals && fundamentals.financials ? getChronologicalKeys(fundamentals.financials) : null;
    const bs_dates=fundamentals && fundamentals.balance_sheet ? getChronologicalKeys(fundamentals.balance_sheet) : null;
    const cf_dates=fundamentals && fundamentals.cashflow ? getChronologicalKeys(fundamentals.cashflow) : null;
    
return(
    <Modal
    show={showInfo}
    onHide={()=>setShowInfo(!showInfo)}
    fullscreen={true}
    
    >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <Container className='p-0 text-start mb-5'  style={{ height: "100%", width: "100%" }}>
                <Row className='mb-5'>
                    <Col className = 'd-flex align-items-center  flex-row-reverse'>
                    <img className= "shadow-sm bg-light" style={{objectFit:"contain"}} width="75" height="75" src={"https://logo.clearbit.com/"+line.website} onError={addDefaultImg} loading="lazy"></img>
                    </Col>
                    <Col className='align-self-center'>
                        <h6 className='mb-0 fw-bold fs-3'>{line.companyname}</h6>
                        <small className="text-muted fw-bold fs-5">{line.ticker}</small>
                    </Col>
                </Row>
                <Row className='square border-bottom pt-1 pb-1'>
                    <Col lg={2}></Col>
                    {annotations===null &&
                    <Col xs ='12' lg='4'>
                        <Row>
                            <Col lg='12' className='d-flex'>
                                <div className='fw-bold me-2'>{t('tablah.lastclosing')+':'}</div>
                                <div>{formatter.format(line.close)} <small>{line.currency}</small></div> 
                            </Col>
                            <Col lg='12' className='d-flex'>
                                <div className='fw-bold me-2'>{t('tablah.volume')+':'}</div>
                                <div>{formatterVolume.format(line.volume)}</div> 
                            </Col>
                            
                            <Col lg='12' className='d-flex'>
                                <div className='fw-bold me-2'>{t('graphinfo.year_change')+':'}</div>
                                <div>{formatter.format(line.year_change*100)} %</div> 
                            </Col>
                        </Row>
                    </Col>}
                    <Col xs ='12' lg='4'>
                        <Row>
                        <Col lg='12' className='d-flex'>
                                <div className='fw-bold me-2'>{t('tablah.exchange')+':'}</div>
                                <div>{t('data.exchange.'+line.exchange)}</div>
                            </Col>
                            <Col lg='12' className='d-flex'>
                                <div className='fw-bold me-2'>{t('tablah.country')+':'}</div>
                                <div>{t('data.country.'+line.country)}</div>
                            </Col>
                            <Col lg='12' className='d-flex'>
                                <div className='fw-bold me-2'>{t('tablah.sector')+':'}</div>
                                <div>{t('data.sector.'+line.sector)}</div>
                            </Col>
                            <Col lg='12' className='d-flex'>
                                <div className='fw-bold me-2'>{t('tablah.industry')+':'}</div>
                                <div>{t('data.industry.'+line.industry)}</div>
                            </Col>
                            <Col lg='12' className='d-flex'>
                                <div className='fw-bold me-2'>{t('tablah.website')+':'}</div>
                                {/* {user!=null && user.subscription==='free' 
                                ?
                                <div className='border rounded' style={{'backgroundColor':'silver', 'color':'gray'}}><small>{t('graphinfo.unlockWithPremium')}</small></div>
                                :
                                <div><a href={'https://www.'+line.website}  target="_blank">{'www.'+line.website}</a></div>
                                } */}
                                <div><a href={'https://www.'+line.website}  target="_blank">{'www.'+line.website}</a></div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={2}></Col>
                </Row>
                <Row>
                    <Col className='text-center mt-2'>
                    {user && user.subscription ==='free' &&
                    <Link  className='bg-transparent fw-bold container-fluid align-self-center mb-1 text-center' to={'/pricing'}>
                        {t('graphinfo.unlockWithPremium')}
                    </Link>
                    }
                    </Col>
                </Row>
                {pl && pl_dates && cf &&cf_dates && <Row className='mt-5 tableText'>
                    <Col lg={6} className='pe-lg-5'>
                        <Container >
                            <Row >
                                <Col className='fs-3 fw-bold text-center'>
                                    {t('graphinfo.pl.title')}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4} className='text-end'>
                                    <small>({line.currency})</small>
                                </Col>
                                <Col xs={3} className='text-end fw-bold'>
                                {pl_dates[1]}
                                </Col>
                                <Col xs={3} className='text-end fw-bold'>
                                {pl_dates[0]}
                                </Col>
                                <Col xs={2} className='text-end fw-bold'>
                                {"∆%"}
                                </Col>
                            </Row>
                           {InfoLine('Total Revenue', pl['Total Revenue'], 'pl', pl_dates, false, 'fw-bold')}
                           {InfoLine('Cost Of Revenue', pl['Cost Of Revenue'], 'pl', pl_dates, true, 'fw-bold text-muted border-bottom border-2')}
                           {InfoLine('Gross Profit', pl['Gross Profit'], 'pl', pl_dates, false, 'fw-bold')}
                           {InfoLine('Operating Expense', pl['Operating Expense'], 'pl', pl_dates, true, 'fw-bold text-muted')}
                           {InfoLine('Operating Income', pl['Operating Income'], 'pl', pl_dates, false, 'fw-bold text-muted border-bottom border-2')}
                           {InfoLine('EBITDA', pl['EBITDA'], 'pl', pl_dates, false, 'fw-bold')}
                           {InfoLine('Interest Expense', pl['Interest Expense'], 'pl', pl_dates, true, 'fw-bold text-muted border-bottom border-2')}
                           {InfoLine('Net Income', pl['Net Income'], 'pl', pl_dates, false, 'fw-bold')}

                        </Container>
                    </Col>

                    <Col lg={6}>
                        <Container className='ps-lg-3 mt-5 mt-lg-0'>
                            <Row >
                                <Col className='fs-3 fw-bold text-center'>
                                    {t('graphinfo.cf.title')}
                                </Col>
                            </Row>
                            <Row >
                                <Col xs={4} className='text-end'>
                                    <small>({line.currency})</small>
                                </Col>
                                <Col xs={3} className='text-end fw-bold'>
                                {cf_dates[1]}
                                </Col>
                                <Col xs={3} className='text-end fw-bold'>
                                {cf_dates[0]}
                                </Col>
                                <Col xs={2} className='text-end fw-bold'>
                                {"∆%"}
                                </Col>
                            </Row>
                           {InfoLine('Net Income', pl['Net Income'], 'cf', pl_dates, false, 'fw-bold')} 
                           {InfoLine('Operating Cash Flow', cf['Operating Cash Flow'], 'cf', cf_dates, false, 'fw-bold text-muted')}
                           {InfoLine('Investing Cash Flow', cf['Investing Cash Flow'], 'cf', cf_dates, false, 'fw-bold text-muted')} 
                           {InfoLine('Financing Cash Flow', cf['Financing Cash Flow'], 'cf', cf_dates, false, 'fw-bold text-muted')}
                           {InfoLine('Repayment Of Debt', cf['Repayment Of Debt'], 'cf', cf_dates, false, 'fw-bold text-black-50')}
                           {InfoLine('Depreciation And Amortization', cf['Depreciation And Amortization'], 'cf', cf_dates, false, 'fw-bold text-black-50')}
                           {InfoLine('Issuance Of Debt', cf['Issuance Of Debt'], 'cf', cf_dates, true, 'fw-bold text-black-50')}
                           {InfoLine('Capital Expenditure', cf['Capital Expenditure'], 'cf', cf_dates, true, 'fw-bold text-black-50')}
                           {InfoLine('Cash Dividends Paid', cf['Cash Dividends Paid'], 'cf', cf_dates, false, 'fw-bold text-black-50')}
                           {InfoLine('Free Cash Flow', cf['Free Cash Flow'], 'cf', cf_dates, false, 'fw-bold')}

                        </Container>
                    </Col>
                </Row>
                }
                {bs && bs_dates && <Row className='mt-5 tableText'>
                    <Col lg={2}></Col>
                    <Col lg={8}>
                        <Container className='ps-1'>
                            <Row >
                                <Col className='fs-3 fw-bold text-center'>
                                    {t('graphinfo.bs.title')}
                                </Col>
                            </Row>
                            <Row >
                                <Col xs={4} className='text-end'>
                                    <small>({line.currency})</small>
                                </Col>
                                <Col xs={3} className='text-end fw-bold'>
                                {bs_dates[1]}
                                </Col>
                                <Col xs={3} className='text-end fw-bold'>
                                {bs_dates[0]}
                                </Col>
                                <Col xs={2} className='text-end fw-bold'>
                                {"∆%"}
                                </Col>
                            </Row>
                           {InfoLine('Current Assets', bs['Current Assets'], 'bs', bs_dates, false, 'fw-bold text-muted')}
                           {InfoLine('Cash And Cash Equivalents', bs['Cash And Cash Equivalents'], 'bs', bs_dates, false, 'fw-bold text-black-50  text-end')}
                           {InfoLine('Accounts Receivable', bs['Accounts Receivable'], 'bs', bs_dates, false, 'fw-bold text-black-50  text-end')}
                           {InfoLine('Inventory', bs['Inventory'], 'bs', bs_dates, false, 'fw-bold text-black-50  text-end')}
                           {InfoLine('Total Non Current Assets', bs['Total Non Current Assets'], 'bs', bs_dates, false, 'fw-bold text-muted')}
                           {InfoLine('Total Assets', bs['Total Assets'], 'bs', bs_dates, false, 'fw-bold  border-bottom border-2')}
                           {InfoLine('Current Liabilities', bs['Current Liabilities'], 'bs', bs_dates, true, 'fw-bold text-muted')}
                           {InfoLine('Accounts Payable', bs['Accounts Payable'], 'bs', bs_dates, true, 'fw-bold text-black-50  text-end')}
                           {InfoLine('Total Non Current Liabilities Net Minority Interest', bs['Total Non Current Liabilities Net Minority Interest'], 'bs', bs_dates, true, 'fw-bold text-muted')}
                           {InfoLine('Total Liabilities Net Minority Interest', bs['Total Liabilities Net Minority Interest'], 'bs', bs_dates, true, 'fw-bold  border-bottom border-2')}
                           {InfoLine('Capital Stock', bs['Capital Stock'], 'bs', bs_dates, false, 'fw-bold text-muted')}
                           {InfoLine('Retained Earnings', bs['Retained Earnings'], 'bs', bs_dates, false, 'fw-bold text-muted')}
                           {InfoLine('Total Equity Gross Minority Interest', bs['Total Equity Gross Minority Interest'], 'bs', bs_dates, false, 'fw-bold')}
                          

                        </Container>
                    </Col>
                    <Col lg={2} className='mb-5'></Col>
                </Row>}
                <Row className='mt-5 text-center text-muted'>
                    <small>{t('graphinfo.disclaimer')}</small>
                </Row>
            </Container>
        </Modal.Body>
    </Modal>

  );

};

export default GraphInfo;