// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';
import Modal from 'react-bootstrap/Modal';

function TradingViewWidget({symbol, exchange, showTradingView, setShowTradingView}) {
  const tradigViewModalRef = useRef();
  useEffect(
    () => {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
            "container_id": "technical-analysis-chart-demo",
            "width": "100%",
            "height": "100%",
            "autosize": true,
            "symbol": "` + exchange + ":" + symbol.split(".")[0] + `",
            "interval": "D",
            "timezone": "exchange",
            "theme": "light",
            "style": "1",
            "withdateranges": true,
            "hide_side_toolbar": false,
            "allow_symbol_change": true,
            "save_image": true,
            "studies": [
            ],
            "show_popup_button": false,
            "popup_width": "1000",
            "popup_height": "650",
            "support_host": "https://www.tradingview.com"
        }`;
        if (tradigViewModalRef.current) {
          tradigViewModalRef.current.appendChild(script);
          }
    },
    [showTradingView]
  );

  return (
    <>

      {exchange 
      &&
      <Modal fullscreen={true} show={showTradingView} onHide={()=>setShowTradingView(!showTradingView)}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="tradingview-widget-container" ref={tradigViewModalRef} style={{ height: "100%", width: "100%" }}>
              <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
          </div>
        </Modal.Body>
      </Modal> 
      }
  </>
    
  );
}

export default memo(TradingViewWidget);