import React from 'react';
import { LabelAnnotation, Annotate, LineSeries} from "react-financial-charts";
import { positionColor } from '../../../utils/colors';
import { customColorsVariable } from '../../../utils/colors';
import './annotations.css'

const GraphAnnotations = ({ annotations}) => {
  const userLanguage = navigator.language || navigator.userLanguage;
  const formatter = new Intl.NumberFormat(userLanguage, {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  });
  const colorsLine = {
  "1": positionColor.lightLong,
  "-1": positionColor.lightShort,
  };
  return ( 
    <>
      {annotations && annotations.map((annotation, index) => {
        return (
           <>
            <Annotate
              with={LabelAnnotation}
              usingProps={{
                text: () => {
                  return annotation.profitability < 0
                    ? formatter.format(annotation.profitability*100) + '%'
                    : '+'+formatter.format(annotation.profitability*100) + '%';
                },
                textAnchor: "end",
                className: "bold-text",
                fontSize: 15,
                y: ({ yScale}) => (index % 2  === 0) ? yScale(-0.15) : yScale(0.03), 
                fill: () => {
                  return annotation.profitability < 0
                    ? customColorsVariable.danger
                    : customColorsVariable.success;
                },
              }}
              when={(datum) =>{
                return annotation.date_exit === datum.date
              }}
            />
            <LineSeries
              yAccessor={(d) => {
                let dateExit = new Date(annotation.date_exit)
                let dateEntry = new Date(annotation.date_entry)
                if(
                  d.idx.date >= dateEntry &&
                  d.idx.date <= dateExit){
                  return 0;
                } else {
                  return undefined;
                }
              }}
              strokeStyle={colorsLine[annotation.position]}
              strokeWidth={20}
              highlightOnHover
              interactiveCursor="pointer"
              edgeInteractiveCursor="crosshair"
              /* strokeDasharray={()=>{
                  let dateExit = new Date(annotation.date_exit)
                  let dateEntry = new Date(annotation.date_entry)
                if (
                  
                  dateEntry-dateExit===0 
                ) {
                  return 'Dash';
                }else {
                  return undefined;
                }
              }} */
            />
          </>
        );
        })
        }
    </>
  );
};

export default GraphAnnotations;