import { useState } from 'react';
import {Button, Collapse} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { googleLogout } from '@react-oauth/google';
import { setUser } from '../../redux/userReducer';
import { Link, useNavigate } from 'react-router-dom';
import noLogo from '../../assets/no_logo.png';
/* import sadPepe from '../../assets/sadpepeColor.png'; */
import { testmode } from '../../utils/variablesProdTest';
function OffcanvasProfile() {
  //code for navigation
  const navigate = useNavigate();
  // code to get user values from redux
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  // hook for translation
  const { i18n, t } = useTranslation();
  //coltrolling close and open of settings
  const [openuser, setOpenuser] = useState(false);
  //functions to handle logout
  const logOut = () => {
    googleLogout();
    dispatch(setUser(null));
    navigate('/')
  };
  //function to put not found image if there is an error 
  const addDefaultImg = (e) =>   {
    e.target.src = noLogo;
}
  const managingStripeLink = testmode ? 'https://billing.stripe.com/p/login/test_dR6aFt9cBdAX74s3cc': 'https://billing.stripe.com/p/login/aEU02b4R1bnF1l6dQQ'
  const encodedEmail = user.stripe_email ? encodeURIComponent(user.stripe_email): encodeURIComponent(user.email);
  return (
    <>
    {user && 
        <>
        <Button  variant='deafult' className='border-0 border-bottom border-secondary rounded-0 bg-transparent fw-bold container-fluid text-start mb-1 mt-3' 
        onClick={()=>{setOpenuser(!openuser)}}>
            <img alt="User Profile" className="rounded-circle me-2" style={{ width: '24px', height: '24px' }} src={user.info.picture}
            onError={addDefaultImg}></img>
            {t('offcanvas.profile.profile')}
        </Button>
        <Collapse in={openuser}>
           <div className='mt-2'>
              <div className='d-flex mt-3'>
                  <div  className='bg-transparent fw-bold container-fluid align-self-center mb-1' >
                      {t('offcanvas.profile.name')}
                  </div>
                  <div  className='bg-transparent  container-fluid align-self-center mb-1' >
                      {user.info.name}
                  </div>
              </div>
              <div className='d-flex  mt-3'>
                  <div  className='bg-transparent fw-bold container-fluid align-self-center mb-1' >
                      {t('offcanvas.profile.email')}
                  </div>
                  <div  className='bg-transparent  container-fluid align-self-center mb-1' >
                      {user.info.email}
                  </div>
              </div>
              <div className='d-flex mt-3'>
                  <div  className='bg-transparent fw-bold container-fluid align-self-center mb-1' >
                      {t('offcanvas.profile.active_subscription')}
                  </div>
                  <div  className='bg-transparent  container-fluid align-self-center mb-1' >
                  {t('offcanvas.profile.'+user.subscription)}

                  </div>
              </div>
              <div className='d-flex mt-3'>
                {user.subscription ==='free' ?
                    <Link  className='bg-transparent fw-bold container-fluid align-self-center mb-1 text-center' to={'/pricing'}>
                        {t('offcanvas.profile.get_subscription')}
                    </Link>
                    :
                    <Link  className='bg-transparent fw-bold container-fluid align-self-center mb-1 text-center' to={managingStripeLink+'?prefilled_email='+encodedEmail}>
                      {t('offcanvas.profile.manage_subscription')}
                    </Link>
                }
                  
              </div>
              
            <Button className='container-fluid text-white fw-bold btn-primary  mt-4' onClick={logOut}>
                {t('offcanvas.profile.logout')}
                {/* <img alt="sad" className=" m-1 ms-2" style={{ width: '40px'}} src={sadPepe}></img> */}
            </Button>
           </div>
        </Collapse>
    </>}
    </>
  );
}

export default OffcanvasProfile;