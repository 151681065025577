// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userReducer';
import settingsReducer from './settingsReducer';

const store = configureStore({
  reducer: {
    user: userReducer,
    settings:settingsReducer
    // Add other reducers if needed
  },
});

export default store;