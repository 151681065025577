import React, { useState, useRef } from 'react';
import { format } from "d3-format";
import {
    rsi,
    bollingerBand,
    discontinuousTimeScaleProviderBuilder,
    Chart,
    ChartCanvas,
    CurrentCoordinate,
    BarSeries,
    RSITooltip,
    RSISeries,
    BollingerSeries,
    BollingerBandTooltip,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    EdgeIndicator,
    MouseCoordinateX,
    MouseCoordinateY,
} from "react-financial-charts";
import GraphCandlestick from './GraphCandlestick';
import GraphAnnotations from './annotations/GraphAnnotations';
//import adjustAnnotations from './annotations/adjustAnnotations';

const GraphBollingerRSI = ({strategy, stock_data, params, graph_width, annotations}) => {
    const ScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
        (d) => new Date(d.date)
    );
    let annotationHeight = 0;
    if (annotations){
        annotationHeight = 80;
    }
    const height = 300 + annotationHeight;
    const width = graph_width;
    const margin = { left: 0, right: 60, top: 0, bottom: 20};
    const bb = bollingerBand()
        .id(1)
        .options({ windowSize: params.k1 ,
                    movingAverageType:'sma'})
        .merge((d, c) => {
            d.bb = c;
        })
        .accessor((d) => d.bb);
    const rsi1 = rsi()
        .id(2)
        .options({windowSize: params.k2})
        .merge((d, c) => {
            d.rsi1 = c;
        })
        .accessor((d) => d.rsi1); 
    
    //this iswhat calculates the curves  what the fuck...
    const calculatedData = bb(rsi1(stock_data));
    const { data, xScale, xAccessor, displayXAccessor } = ScaleProvider(
        calculatedData
    );

    const pricesDisplayFormat = format(".2f");
    const max = xAccessor(data[data.length - 1]);
    const min = xAccessor(data[Math.max(0, data.length - 20)]);//this controls the initial days viewed but it does not work as it think it should
    const xExtents =[min, max+5];
    const gridHeight = height - margin.top - margin.bottom;
    const barChartHeight = gridHeight / 4;
    const bbHeight = 80;
    const barChartOrigin = (_, h) => [0, h - barChartHeight-bbHeight- annotationHeight];
    const chartHeight = gridHeight - bbHeight- annotationHeight;
    const bbChartOrigin = [0, gridHeight-bbHeight- annotationHeight];
    const barChartExtents = (data) => {
        return data.volume;
    };

    const candleChartExtents = (data) => {
        return [data.high, data.low];
    };


    const yEdgeIndicator = (data) => {
        return data.close;
    };

    const volumeColor = (data) => {
        return data.close > data.open
            ? "rgba(38, 166, 154, 0.3)"
            : "rgba(239, 83, 80, 0.3)";
    };

    const volumeSeries = (data) => {
        return data.volume;
    }; 

    const openCloseColor = (data) => {
        return data.close > data.open ? "#26a69a" : "#ef5350";
    };
    // code for anotations
    const [scale, setScale] = useState();
    const [adjustedAnnotations, setAdjustedAnnotations] = useState(null);
    /* useEffect(() => {
        if (annotations!=null){
            let r =adjustAnnotations(annotations);
            setAdjustedAnnotations(r.adjustedAnnotations);
            setScale(r.scale)
        }
      }, [annotations]);  */ 

    const chartRef = useRef(null);
    return (
        <ChartCanvas
            height={height}
            ratio={3}
            width={width}
            margin={margin}
            data={data}
            displayXAccessor={displayXAccessor}
            seriesName="Data"
            xScale={xScale}
            xAccessor={xAccessor}
            xExtents={xExtents}
            zoomAnchor={lastVisibleItemBasedZoomAnchor}
            
        >
            <Chart
                id={2}
                height={barChartHeight}
                origin={barChartOrigin}
                yExtents={barChartExtents}
                
            >
                <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries} />
            </Chart> 
            <Chart id={1} height={chartHeight} yExtents={candleChartExtents}
            ref={chartRef}>
                
                <GraphCandlestick chartRef={chartRef}/>
                <BollingerSeries yAccessor={bb.accessor()} strokeStyle={bb.stroke()}/>

                <CurrentCoordinate
                    yAccessor={bb.accessor()}
                    fillStyle={bb.stroke()}
                />
                <MouseCoordinateY
                    rectWidth={margin.right}
                    displayFormat={pricesDisplayFormat}
                />
                <EdgeIndicator
                    itemType="last"
                    rectWidth={margin.right}
                    fill={openCloseColor}
                    lineStroke={openCloseColor}
                    displayFormat={pricesDisplayFormat}
                    yAccessor={yEdgeIndicator}
                />
                <BollingerBandTooltip
                    textFill='var(--bs-body-color)'
                    origin={[8, 27]}
                    options={
                        {
                            yAccessor: bb.accessor(),
                            stroke: bb.stroke(),
                            windowSize: bb.options().windowSize,
                            multiplier: bb.options().multiplier,
                            sourcePath: bb.options().sourcePath,
                            movingAverageType: bb.options().movingAverageType,
                        }
                    }
                />
                

                
            </Chart>
            <Chart id={3} yExtents={rsi1.accessor()} height={bbHeight} origin={bbChartOrigin}>
                {/* {Code for the dates and the date tooltip} */}
                <MouseCoordinateX
                at="bottom"
                orient="bottom"
                displayFormat={(d) => d.toLocaleDateString()}/>
                <XAxis showGridLines gridLinesStrokeStyle="#e0e3eb" strokeStyle='var(--bs-body-color)' tickLabelFill='#949191' tickStrokeStyle="#949191"/>
                <YAxis tickValues={[params.threshold_down, 50, params.threshold_up]}  tickLabelFill='#949191' tickStrokeStyle="#949191" strokeStyle='var(--bs-body-color)'/>

                <RSISeries yAccessor={rsi1.accessor()} />

                <RSITooltip origin={[8, 16]} yAccessor={rsi1.accessor()} options={rsi1.options()} textFill='var(--bs-body-color)'/>
                
            </Chart>
            {(annotations!=null) &&
            <Chart id={5} yExtents={[-0.5,0.5]} height={annotationHeight} origin={[0, gridHeight-annotationHeight]}>
                <GraphAnnotations annotations={annotations}></GraphAnnotations>

            </Chart>}
            <CrossHairCursor />
        </ChartCanvas>
    );
};
export default GraphBollingerRSI;