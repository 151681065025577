import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import { host } from '../../utils/variablesProdTest';
import './RankingCarousel.css';
const RankingCarousel = ({filter, volume})=> {
  // hook for translation
  const { i18n, t } = useTranslation();
  // this is the number formatter
  const userLanguage = navigator.language || navigator.userLanguage;
  const formatter = new Intl.NumberFormat(userLanguage, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  const [tablesWin, setTablesWin] = useState(null)
  const [tablesLose, setTablesLose] = useState(null)  

  const axis = ['strategy', 'sector', 'exchange', 'industry']
  const fetchTables = () => {
    const promises = axis.map((ax) =>
    axios.post(host + "summaryRanking.php", {
      axis: ax,
      strategy: filter.strategy, 
      day_period: filter.day_period, 
      volume: volume,
      country: filter.country,
      sector: filter.sector,
      industry: filter.industry,
      exchange: filter.exchange,
    })
    .then(data => ( { [ax]: data.data } ))
    .catch((error) => {
      console.error('Error fetching data:', error);
      return { [ax]: null }; // or handle the error as needed
    })
  );

  Promise.all(promises)
    .then(results => {
      const res = results.reduce((acc, result) => ({ ...acc, ...result }), {});
      const transform = generateWinLose(res)
      setTablesWin(transform.win);
      setTablesLose(transform.lose);
    })
    .catch((error) => {
      console.error('Promise.all error:', error);
      setTablesWin({});
    });
};

function generateWinLose(jsonData) {
  const x = 5;
  const winJson = { strategy: [], sector: [], exchange: [], industry: [] };
  const loseJson = { strategy: [], sector: [], exchange: [], industry: [] };

  for (const category in jsonData) {
    if (jsonData.hasOwnProperty(category)) {
      const categoryData = jsonData[category];
      const sortedData = categoryData.sort((a, b) => parseFloat(b.total_profit) - parseFloat(a.total_profit));

      const winData = sortedData.filter(entry => parseFloat(entry.total_profit) > 0).slice(0, x);
      const loseData = sortedData.filter(entry => parseFloat(entry.total_profit) < 0).slice(0, x).reverse();

      winJson[category] = winData;
      loseJson[category] = loseData;
    }
  }

  return { win: winJson, lose: loseJson };
}
useEffect(() => {
  if (filter!=null){
    fetchTables();
  }
}, [filter]);

  const CarouselLine = (table) => {
  return(
    <Container>
      {table.map((line)=>{
        let key = Object.keys(line)[0]
        return (<Row className='square border-bottom pt-1 pb-1'>
            <Col xs={1}></Col>
            <Col xs={8} className = 'd-flex align-items-center text-line-carousel fw-bold'>
              {t('data.'+ key +'.'+line[key])}
            </Col>
            <Col xs={2} className ={'fs-6 fw-bold d-flex align-items-center '+(line.total_profit > 0 ? 'text-success' : 'text-danger') }>
            {(line.total_profit > 0 ? '+' : '') + formatter.format(line.total_profit*100) + '%'}
            </Col>
            <Col xs={1}></Col>
        </Row>)
      })}
    </Container>
  );

  };

  return (
  <> 
  <Container className='mt-1'>
    <Row>
      <Col xs={12} lg={6} className='p-2'>
                
      {tablesWin && <Carousel
      className='border border-success rounded p-1 carousel-height'>
        
      {axis.map((key)=>{ 
        return(
        <Carousel.Item >
          <div className='text-center fs-3  fw-bold text-success'>
            {t('ranking.carousel1') + t('tablah.'+key) + t('ranking.carousel2Winners')}
          </div>
          {tablesWin[key] && tablesWin[key].length > 0 ?
            CarouselLine(tablesWin[key]):
            <div style={{color: 'lightgrey'}} className='text-center fs-2 fw-bold h-100 w-100'>
              {t('ranking.carouselNoWinners')}
            </div>
          }
        </Carousel.Item>
        )
      })}
        </Carousel>}
      </Col>
      <Col xs={12} lg={6} className='p-2'>
      {tablesLose && <Carousel
      className='border border-danger rounded p-1 carousel-height'>
        {axis.map((key)=>{
          
            return(
            <Carousel.Item>
              <div className='text-center fs-3 fw-bold text-danger'>
                {t('ranking.carousel1') + t('tablah.'+key) + t('ranking.carousel2Losers')}
              </div>
                {tablesLose[key] && tablesLose[key].length > 0 ?
                 CarouselLine(tablesLose[key]) :
                 <div style={{color: 'lightgrey'}} className='text-center fs-2 fw-bold h-100 w-100'>
                  {t('ranking.carouselNoLosers')}
                </div>
               }
            </Carousel.Item>
            )
        
      })}
      </Carousel>}
      </Col>
    </Row>
    
    </Container>
    </>
  );
}

export default RankingCarousel;
