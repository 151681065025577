import { useState } from 'react';
import {Button, Collapse} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {TbSettings} from 'react-icons/tb'
import FormSelect from 'react-bootstrap/FormSelect';
import { THEME, setTheme} from '../../utils/theme'
import { useDispatch, useSelector } from 'react-redux';
import { setSettings } from '../../redux/settingsReducer';

function OffcanvasSettings() {
  // code to get settings values from redux
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.settings);
  const setSettings1 = (res)=>{dispatch(setSettings(res))}
  // hook for translation
  const { i18n, t } = useTranslation();
  //coltrolling close and open of settings
  const [opensettings, setOpensettings] = useState(false);
  
  return (
    <>
        <Button  variant='deafult' className='border-0 border-bottom border-secondary rounded-0 bg-transparent fw-bold container-fluid text-start mb-1 mt-3' 
        onClick={()=>{setOpensettings(!opensettings)}}>
            <TbSettings className='text-primary me-2 mb-1 fw-bold h4'/>
            {t('offcanvas.settings.settings')}
        </Button>
        <Collapse in={opensettings}>
            <div className='mt-4'>
              <div className='d-flex'>
                  <div  className='bg-transparent fw-bold container-fluid align-self-center mb-1' >
                      {t('offcanvas.settings.candlestick')}
                  </div>
                  <FormSelect   onChange={(e)=>{setSettings1({...settings, graph: {...settings.graph, color: e.target.value}})}}  className='border-secondary' defaultValue={settings.graph.color}>
                  <option value="blackAndWhite">{t('offcanvas.settings.candlestick_settingsBlackWhite') }</option>
                  <option value="redAndGreen">{t('offcanvas.settings.candlestick_settingsRedGreen')}</option>
                  </FormSelect>
              </div>
              <div className='d-flex mt-4'>
                  <div  className='bg-transparent fw-bold container-fluid align-self-center mb-1' >
                      {t('offcanvas.settings.theme')}
                  </div>
                  <FormSelect   onChange={(e)=>{setTheme(e.target.value)}}  className='border-secondary' defaultValue={localStorage.getItem('theme')}>
                  <option value={THEME.DARK}>{t('offcanvas.settings.theme_dark') }</option>
                  <option value={THEME.LIGHT}>{t('offcanvas.settings.theme_light')}</option>
                  </FormSelect>
              </div>
            </div>
        </Collapse>
    </>
  );
}

export default OffcanvasSettings;