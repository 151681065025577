import {useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { Collapse, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {BsCaretDown} from 'react-icons/bs'
import { indexesExclusive } from '../../utils/indexesExclusiveBySubscription';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
function FilterSectionNested({k, optionsParent, optionsSon, configureSelection, converter, parent, son}) {
    const path = useLocation().pathname;

    //get user from redux
    const user = useSelector((state) => state.user.user);
    // hook for translation
    const { i18n, t } = useTranslation();
    const [allSelected, setAllSelected]=useState(true);
    const onChangeSelection = (e) =>   {
      configureSelection(parent,e.target.name, e.target.checked);
      Object.keys(optionsSon).map(
        (i)=>{
          if (converter[e.target.name].includes(i)){
            configureSelection(son,i,  e.target.checked);
          }
        }
      )
    }
    const selectAll = (e) =>{
      setAllSelected(e.target.checked);
      Object.keys(optionsParent).map((s)=>{
        configureSelection(parent,s, e.target.checked)
      });
      Object.keys(optionsSon).map(
        (i)=>{
          
          configureSelection(son,i,  e.target.checked);
          
        }
      )
    }
    const [open, setOpen]=useState(false);

    const SonElement = (s)=>{
      const [openSon, setOpenSon]=useState(false);
      return (
        
        <div  className = 'm-2 p-1 border rounded border-secondary d-flex flex-wrap'>
          <Form.Check onChange={onChangeSelection}  checked={optionsParent[s]}  name={s} id={s} label={t('data.'+parent+'.'+s)}/>
          <BsCaretDown className='text-primary me-2 mb-0 h4 fw-bold' onClick={()=>setOpenSon(!openSon)}/>
          <Collapse in ={openSon}>
            <div>
              <div className='d-flex flex-wrap'>
              {Object.keys(optionsSon).map((i) => {
                if (converter[s].includes(i)){
                  return (
                  <div  className = 'm-1 p-1 border rounded border-secondary'>
                    <Form.Check onChange={(e)=>{
                       configureSelection(son,e.target.name, e.target.checked);
                       if(e.target.checked===true){
                        configureSelection(parent,s, true);
                       }
                      }}  
                      checked={optionsSon[i]}  name={i} id={i} label={t('data.'+son+'.'+i)}/>
                    {}
                    </div>
                  )
                }
                
                })}
                
                {son==='indexes' && user.subscription==='free' && path==='/todaysignals' &&
                  indexesExclusive['premium'].map((i) => {
                    if (converter[s].includes(i)){
                      return(
                        <OverlayTrigger  placement="top" delay={{ show: 100, hide: 100 }}
                        overlay={(props) => (
                        <Tooltip id="button-tooltip"
                            {...props}
                        >
                            {t('offcanvas.filter_getWithPremium')}
                        </Tooltip>
                        )}
>
                          <div  className = 'm-1 p-1 border rounded border-secondary'>
                          <Form.Check disabled checked={false}  name={i} id={i} label={t('data.'+son+'.'+i)}/>
                          </div>
                        </OverlayTrigger>
                      )
                    }
                  })
                }

                {son==='indexes' && user.subscription!=='pro' && path==='/todaysignals' &&
                  indexesExclusive['pro'].map((i) => {
                    if (converter[s].includes(i)){
                      return(
                        <OverlayTrigger  placement="top" delay={{ show: 100, hide: 100 }}
                        overlay={(props) => (
                        <Tooltip id="button-tooltip"
                            {...props}
                        >
                            {t('offcanvas.filter_getWithPro')}
                        </Tooltip>
                        )}
>
                          <div  className = 'm-1 p-1 border rounded border-secondary'>
                          <Form.Check disabled checked={false}  name={i} id={i} label={t('data.'+son+'.'+i)}/>
                          </div>
                        </OverlayTrigger>
                      )
                    }
                  })
                }
              </div>
            </div>
          </Collapse>
        </div>
          
      )
    }
  return (
    <div className='m-2 mt-4'>
      <Button  variant='deafult' className='border-0 border-bottom border-secondary rounded-0 bg-transparent fw-bold container-fluid text-start' 
              onClick={() => setOpen(!open)}>
        <BsCaretDown className='text-primary me-2 mb-0 h4 fw-bold'/>{t('tablah.' + k)}
        
      </Button>
      <Collapse in ={open}>
        <div >
        <div className='d-flex flex-row'>
          <div  className='m-1 p-1 pe-2 border rounded border-secondary'>
            <Form.Check onChange={selectAll}  checked={allSelected} id={k} label={t('offcanvas.filter_select_all')} className='fw-bold '/>
          </div>
          </div>
        <div className='d-flex flex-wrap'>
              {Object.keys(optionsParent).map(s => (
                SonElement(s)
              ))}
        </div>
        </div>
      </Collapse>
    </div>
  );
}

export default FilterSectionNested;