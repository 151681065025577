import './App.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Graphtable from '../components/todaysignals/Graphtable';
import FilterOffcanvas from '../components/offcanvas/FilterOffcanvas';
import Header from '../components/header/Header';
import Footer from '../components/Footer';
import {initTheme} from '../utils/theme'
import TodaysignalsFilter from '../components/todaysignals/TodaysignalsFilter';
import { host } from '../utils/variablesProdTest';
import { useSelector } from 'react-redux';
import SignInModal from '../components/landing/SingInModal';
import SubscribeOnScreen from '../components/subscribeOnScreen/SubscribeOnScreen';
import AlertTopBar from '../components/todaysignals/AlertTopBar';

function App() {
  // code for initializing the theme
  initTheme()
  // code for getting the user from redux
  const user = useSelector((state) => state.user.user);
  // code for updating tables
  const [table, setTable] = useState([]);
  const [totalLines, setTotalLines] = useState(null);
  const [offset, setOffset]=useState(0);
  const volume = 0;
  //explain selection
  const [selection, setSelection] = useState({});
  const [filter, setFilter] = useState(null);


  const fetchFilterTable = () =>   {
    axios.post(host+ "filterTable.php", 
      {
        subscription:user.subscription
      }
    ).then(data => {
      
      let parsed = JSON.parse(data.data[0].filter); //parsing string to json
      let change = [];
      for (let i in parsed.position){ //to handle the change of -1 and 1 to short and long
        if(parsed.position[i]===-1){
          change.push('short');
        }else{
          change.push('long');
        }
      }
      parsed.position = change;

      let opt ={};
      for (const [column, list] of Object.entries(parsed)) {
        opt[column] = {};
        for(let i in list) {
          opt[column][list[i]] = true;
        }

      }
      setSelection(opt);
      // repeated logic for setting the filter
      let res = {};
      for (const [column, list] of Object.entries(opt)) {
        let s = [];
        for(const [k, v] of Object.entries(list)) {
          if (column==='position'){
            if (k==='short' && v){ // workarroud to convert code of position long short to 1 and -1 for db
              s.push('-1');
            }else if (k==='long' && v){
              s.push('1');
            }
          
          }else{
            if (v){
              s.push(k);
            }
          }
        }
        res[column] = s;
      }
      setFilter(res);
      })    
  }

  const fetchTable = () =>   {
    axios.post(host+ "graficar.php", 
      
      {
        strategy: filter.strategy, 
        position: filter.position, 
        volume: volume,
        country: filter.country,
        sector: filter.sector,
        industry: filter.industry,
        exchange: filter.exchange,
        indexes:filter.indexes,
        offset: offset
      }
    ).then(data => {
      setTable(prevTable => [...prevTable, ...data.data]);
      setOffset(prevOffset => prevOffset + 15);
      }
    ).catch((error) => {
        // Error
        setTable([]);
        setOffset(0);
        if (error.response) {
            
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    })
  }
  const fetchRowNumber = () =>   {
    axios.post(host+ "contarRegistros.php", 
    
      {
        strategy: filter.strategy, 
        position: filter.position, 
        volume: volume,
        country: filter.country,
        sector: filter.sector,
        industry: filter.industry,
        exchange: filter.exchange,
        indexes:filter.indexes
      }
    ).then(data => {
      setTotalLines(data.data[0].total_rows);
      }).catch((error) => {
        // Error
        setTotalLines(0);
        if (error.response) {
            
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    })  
  }
  useEffect(() => {
    if (user!=null){
      fetchFilterTable();
    }
  }, [user]);

  useEffect(() => {
    if (filter!=null){
      fetchRowNumber();
      fetchTable();
    }
  }, [filter]);

  
  // this manages the open and close of the offcanvas
  // if it was open and it is closed we filter de selection
  const [open, setOpen] = useState(false);
  const handleShow = () =>   {
    if (open===true){
      filterSelection();
    }
    setOpen(!open);
    
  }
  
  //code for managing the selection done in the filter

  const configureSelection = (column, field, isChecked) =>   {
    if (isChecked){
      setSelection((prev)=>{return{...prev, 
        [column]:{
          ...prev[column],
          [field]:true}}});
    }else{
      setSelection((prev)=>{return{...prev, 
        [column]:{
          ...prev[column],
          [field]:false}}});
    }
  } 
  // code for apliying the filter once is clicked the button
  const filterSelection = () =>   {
    let res = {};
    for (const [column, list] of Object.entries(selection)) {
      let s = [];
      for(const [k, v] of Object.entries(list)) {
        if (column==='position'){
          if (k==='short' && v){ // workarroud to convert code of position long short to 1 and -1 for db
            s.push('-1');
          }else if (k==='long' && v){
            s.push('1');
          }
        
        }else{
          if (v){
            s.push(k);
          }
        }
      }
      res[column] = s;
    }
    setTable([]);
    setOffset(0);
    setTotalLines(0);
    setFilter(res);
    
  } 
  return (
  <div>
  <Header handleShow={handleShow}></Header> 
  
  {user && user.subscription==='free'&&
    <SubscribeOnScreen></SubscribeOnScreen>
  }
  {user===null && 
    <SignInModal show={true} onHide={null} url2navigate={null}></SignInModal>
  }
  {user &&  
    <AlertTopBar></AlertTopBar> 
  }
  {user &&  
    <TodaysignalsFilter selection={selection}></TodaysignalsFilter>
  }
  <FilterOffcanvas open={open} handleShow={handleShow} filter_options={selection} 
                   configureSelection={configureSelection} filterSelection ={filterSelection}></FilterOffcanvas>
  
  <Graphtable table={table} totalLines={totalLines} offset={offset} fetchTable={fetchTable} mode={"todaySignals"}/>
  
  <Footer></Footer> 
</div>
  );
}

export default App;
