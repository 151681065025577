import Header from '../../components/header/Header';
import Footer from '../../components/Footer';
import { Container } from 'react-bootstrap';
import {initTheme} from '../../utils/theme';
import {privacy} from './privacyPolicy';
import {terms} from './termsAndConditions';
export function Privacy() {
  // code for initializing the theme
  initTheme()

  return (
  <div>
  <Header handleShow={0}></Header>
  <Container className='px-3'>
    <div className='mt-5 mb-5' dangerouslySetInnerHTML={{ __html: privacy }} />
   </Container>
  <Footer></Footer> 
</div>
  );
}

export function Terms() {
  // code for initializing the theme
  initTheme()

  return (
  <div>
  <Header handleShow={null}></Header>
  <Container className='px-3'>
    <div className='mt-5 mb-5' dangerouslySetInnerHTML={{ __html: terms }} />
   </Container>
  <Footer></Footer> 
</div>
  );
}

