// getCookieValue.js

export function getCookieValue(cookieName) {
    // Obtén todas las cookies como una cadena de texto
    const cookies = document.cookie;
  
    // Divide la cadena de cookies en pares "clave=valor"
    const cookiesArray = cookies.split('; ');
  
    // Busca la cookie con el nombre deseado
    for (let cookie of cookiesArray) {
      const [name, value] = cookie.split('=');
  
      // Si encuentras la cookie, devuelve su valor decodificado
      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }
  
    // Si no se encuentra la cookie, devuelve null
    return null;
  }