import React, { useState, useEffect, useRef } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import GraphInfo from '../todaysignals/GraphInfo';
import GraphMovingAverageIntersection from '../graph_components/GraphMovingAverageIntersection';
import GraphBollingerRSI from '../graph_components/GraphBollingerRSI';
import GraphMACDEMA from '../graph_components/GraphMACDEMA';
import GraphStochasticRSI from '../graph_components/GraphStochasticRSI';
import GraphSARMACD from '../graph_components/GraphSARMACD';
import noLogo from '../../assets/no_logo.png';

import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import {TbInfoCircle} from 'react-icons/tb';
import {PiChartLineUpBold} from 'react-icons/pi';
import TradingViewWidget from '../graph_components/TradingViewWidget';

import { positionColor } from '../../utils/colors';
const GraphlineTop = ({id, line, settings}) => {
    // hook for translation
    const { i18n, t } = useTranslation();
    const [openGraph, setOpenGraph] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [graph_width, setGraph_width] = useState(300);
    const graph = JSON.parse(line.graph); //we have to parse it because its a string
    // this allows to pass the width of the ref compoonent to the graph so it knows how wide to be
    const ref = useRef(null);
    useEffect(() => {
        if (!ref.current) return;
        const resizeObserver = new ResizeObserver(() => {
            setGraph_width(ref.current ? ref.current.offsetWidth: 300);
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect(); // clean up 
      }, []);
    const positionItem = () =>   {
        let color ='';
        let text ='';
        if(line.position===-1){
            color = positionColor.short;
            text = 'short'
        }else{
            color = positionColor.long;
            text = 'long'
        }
        return( <div className={'text-white text-center fw-bold px-2 rounded'} style={{'backgroundColor':color}}>
                    {t('data.position.'+text)}
                </div>
                );
 
        
    }
    // function to detect error on image and cahnge to no logo
    const addDefaultImg = (e) =>   {
        e.target.src = noLogo;
    }
    // this decides wich graph to display using differents strategies
    const showGraph = ()=>{
        // if annotations does not exist we create it to null so the graphs can handle it
        if (!('annotations' in graph)) {
            graph['annotations'] = null;
          } 
          switch (line.strategy) {
            case 'moving_average_intersection':
                return (
                    <GraphMovingAverageIntersection
                        strategy={line.strategy}
                        stock_data={graph.data}
                        params={graph.params}
                        graph_width={graph_width}
                        settings={settings}
                        annotations={graph.annotations}
                    />
                );
            case 'bollinger_rsi':
                return (
                    <GraphBollingerRSI
                        strategy={line.strategy}
                        stock_data={graph.data}
                        params={graph.params}
                        graph_width={graph_width}
                        settings={settings}
                        annotations={graph.annotations}
                    />
                );
            case 'macd_ema':
                return (
                    <GraphMACDEMA
                        strategy={line.strategy}
                        stock_data={graph.data}
                        params={graph.params}
                        graph_width={graph_width}
                        settings={settings}
                        annotations={graph.annotations}
                    />
                );
            case 'sar_macd':
                return (
                    <GraphSARMACD
                        strategy={line.strategy}
                        stock_data={graph.data}
                        params={graph.params}
                        graph_width={graph_width}
                        settings={settings}
                        annotations={graph.annotations}
                    />
                );
            case 'stochastic_rsi':
                return (
                    <GraphStochasticRSI
                        strategy={line.strategy}
                        stock_data={graph.data}
                        params={graph.params}
                        graph_width={graph_width}
                        settings={settings}
                        annotations={graph.annotations}
                    />
                );
            default:
                return <div>Error Strategy not drawable</div>;
        }
    }
    // this is the number formatter
    const userLanguage = navigator.language || navigator.userLanguage;
    const formatter = new Intl.NumberFormat(userLanguage, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
return(
    
    <Container fluid >
        <Row ref = {ref} className='square border-bottom pt-1 pb-1'>
            <Col xs={2}className = 'd-flex align-items-center'>
            <img className= "rounded-circle shadow-sm bg-light" style={{objectFit:"contain"}} width="50" height="50" src={"https://logo.clearbit.com/"+line.website} onError={addDefaultImg} loading="lazy"></img>
            </Col>
            <Col xs={8}>
                <Container fluid >
                    <Row>
                    <Col xs={12} lg={8}>
                        <Row>
                            <Col xs={12} className='align-self-center'>
                            <h6 className='mb-0'>{line.companyname}</h6>
                            <small className="text-muted">{line.ticker}</small>
                            </Col>
                            <Col xs={12} className='align-self-center'>
                            <small><Link to={'/wiki/'+line.strategy}>{t('data.strategy.'+line.strategy)}</Link></small>
                            </Col>
                        </Row>
                    </Col>
                    {line.day_period && <Col xs={12} lg={4} className={'d-flex align-items-center fs-3 fw-bolder ' + (line.total_profitability > 0 ? 'text-success' : 'text-danger') } >
                    { (line.total_profitability > 0 ? '+' : '' )+ formatter.format(line.total_profitability*100)+'%'}
                    </Col>}
                    </Row>
                </Container>
            </Col>
            <Col xs={2} className = 'd-block d-lg-flex align-items-center mx-0 '>
            <Button type ="Button" variant="outline-secondary" className="me-2 p-1 mt-lg-0 mt-2 mb-lg-0 mb-2" onClick={() => setShowInfo(!showInfo)}>
               <TbInfoCircle className='text-primary h4 m-0' ></TbInfoCircle>
            </Button>
            <Button type ="Button" variant="outline-secondary" className="btn p-1" onClick={() => setOpenGraph(!openGraph)}>
                <PiChartLineUpBold className='text-primary h4 m-0' ></PiChartLineUpBold>
            </Button>
            </Col>
        </Row>
        <Collapse in = {openGraph}  mountOnEnter={true} unmountOnExit={true}>
        <div>
            <div className='text-end'>
                <TradingViewWidget symbol={line.ticker.split(".")[0]}></TradingViewWidget>    
            </div>
            {showGraph()}    
        </div>
        </Collapse>

        <GraphInfo line={line} annotations={graph.annotations} showInfo={showInfo} setShowInfo={setShowInfo}></GraphInfo>
        

    </Container>
  );

};

export default GraphlineTop;