import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Form, Button, Collapse, Row, Col, Container, Spinner} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FilterSection from './FilterSection';
import { TbFilter, TbRotate } from 'react-icons/tb'
import OffcanvasSettings from './OffcanvasSettings';
import OffcanvasProfile from './OffcanvasProfile';
import FilterSectionNested from './FilterSectionNested';
import { sector2industry } from '../../utils/sector2industry';
import { continent2country } from '../../utils/continent2country';
import { continent2indexes } from '../../utils/continent2indexes';
function FilterOffcanvas({open ,handleShow, filter_options, configureSelection, filterSelection}) {
  // hook for translation
  const { i18n, t } = useTranslation();
  //coltrolling close and open of filter
  const [openfilter, setOpenfilter] = useState(false);
  //reset selection
  const resetSelection = (e) =>{
    Object.keys(filter_options).map(k => ( 
     Object.keys(filter_options[k]).map((s)=>{
      if (k!=='day_period'){
        configureSelection(k,s, true)
      }
    })
    ))
  }
  // we do this to get the continents in the correct format for the FilterSectionNested component
  function arrayToJson(array) {
    return array.reduce((acc, current) => {
      acc[String(current)] = true; // Ensures keys are strings
      return acc;
    }, {});
  }
  //controlling filter spinner for user feedback does not track actual loading
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSpinnerReset, setShowSpinnerReset] = useState(false);

  return (
    <>
    <style>
        {`
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(-360deg);
          }
        }

        .rotate-animation {
          animation: rotate 1s linear;
        }
        .offcanvas-body-custom {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
        .offcanvas-footer {
          margin-top: auto;
        }
      `}
      </style>
      <Offcanvas show={open} onHide={handleShow} {...{placement:'end'}}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body  className="offcanvas-body-custom">
          <Button variant='deafult' className='border-0 border-bottom border-secondary rounded-0 bg-transparent fw-bold container-fluid text-start mb-1' 
          onClick={()=>{setOpenfilter(!openfilter)}}>
            <TbFilter className='text-primary me-2 mb-1 fw-bold h4'/>
            {t('offcanvas.filter')}
          </Button>

            <Collapse in={openfilter}>
              <Form>
                  {Object.keys(filter_options).map(k => { 
                    if(k==="day_period"){return <></>} /* we do this to not allow to select various day_period simultaneusly */
                    else if(k==="industry"){return <></>} /* industry must be maped inside sector */
                    else if(k==="continent"){return <></>} /* continent is only to organize indexes and country */
                    else if(k==="sector"){
                      return (<FilterSectionNested id = {k}  k={k} optionsParent = {filter_options['sector']} optionsSon={filter_options['industry']} 
                      configureSelection={configureSelection} converter={sector2industry} parent='sector' son='industry'/>)
                    }
                    else if(k==="indexes"){
                      return (<FilterSectionNested id = {k}   k={k} optionsParent = {filter_options['continent']} optionsSon={filter_options['indexes']} 
                      configureSelection={configureSelection} converter={continent2indexes} parent='continent' son='indexes'/>)
                    }
                    else if(k==="country"){
                      return (<FilterSectionNested id = {k}   k={k} optionsParent = {filter_options['continent']} optionsSon={filter_options['country']} 
                      configureSelection={configureSelection} converter={continent2country} parent='continent' son='country'/>)
                    }  
                    else{
                     return (<FilterSection id = {k}   k={k} options = {filter_options[k]} 
                              configureSelection={configureSelection}/>)
                      }
                  })}
                <Container className='mt-4'> 
                  <Row className='justify-content-md-center'>
                    <Col xs={9} >
                      <Button className='container-fluid text-white fw-bold btn-primary' 
                        onClick={()=>{
                          filterSelection()
                          setShowSpinner(true)
                          setTimeout(() => {  setShowSpinner(false); }, 1000);
                        }}
                      >
                          {t('offcanvas.filter_button')}
                        {' '}
                        {showSpinner &&
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant='secondary'
                        />}
                      </Button>
                      
                    </Col>
                  <Col  xs={3}>
                    <Button variant='default' className='btn-outline-secondary fw-bold' 
                    onClick={()=>{
                      resetSelection()
                      setShowSpinnerReset(true);
                        setTimeout(() => {
                          setShowSpinnerReset(false);
                        }, 1000); // Reset the isClicked state after one second
                    }}>
                      <TbRotate className={`text-primary m-0 fw-bold fs-4 ${
                        showSpinnerReset ? 'rotate-animation' : ''
                      }`}/>
                    </Button> 
                  </Col>
                  </Row> 
                </Container>
              </Form>
            </Collapse>
            <div className="offcanvas-footer">
            <OffcanvasSettings />
            <OffcanvasProfile />
            </div>
            
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default FilterOffcanvas;