import React from 'react';
import { format } from "d3-format";
import {
    ema,
    macd,
    discontinuousTimeScaleProviderBuilder,
    Chart,
    ChartCanvas,
    CurrentCoordinate,
    BarSeries,
    LineSeries,
    MovingAverageTooltip,
    MACDSeries,
    MACDTooltip,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    EdgeIndicator,
    MouseCoordinateX,
    MouseCoordinateY,
} from "react-financial-charts";
import GraphCandlestick from './GraphCandlestick';
import GraphAnnotations from './annotations/GraphAnnotations';
//import adjustAnnotations from './annotations/adjustAnnotations';


const GraphMACDEMA = ({strategy, stock_data, params, graph_width, annotations}) => {
    // necesary to put the MACD tooltip
    const macdAppearance = {
        fillStyle: {
            divergence: "#4682B4",
        },
        strokeStyle: {
            macd: "#0093FF",
            signal: "#D84315",
            zero: "rgba(0, 0, 0, 0.3)",
        },
    };
    const ScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
        (d) => new Date(d.date)
    );

    let annotationHeight = 0;
    if (annotations){
        annotationHeight = 80;
    }
    const height = 300 + annotationHeight;
    const width = graph_width;
    const margin = { left: 0, right: 60, top: 0, bottom: 20};
    const macd1 = macd()
        .id(1)
        .options({ long: params.long_window ,
                   short:params.short_window,
                   signal:params.signal_window})
        .merge((d, c) => {
            d.macd1 = c;
        })
        .accessor((d) => d.macd1);
    const ema1 = ema()
        .id(2)
        .options({windowSize: params.ema_window})
        .merge((d, c) => {
            d.ema1 = c;
        })
        .accessor((d) => d.ema1); 
    //this iswhat calculates the curves  what the fuck...
    const calculatedData = macd1(ema1(stock_data));
    const { data, xScale, xAccessor, displayXAccessor } = ScaleProvider(
        calculatedData
    );
   
    const pricesDisplayFormat = format(".2f");
    const max = xAccessor(data[data.length - 1]);
    const min = xAccessor(data[Math.max(0, data.length - 20)]);//this controls the initial days viewed but it does not work as it think it should
    const xExtents =[min, max+5];
    const gridHeight = height - margin.top - margin.bottom ;
    const barChartHeight = gridHeight / 4;
    const macdHeight = 80;
    const barChartOrigin = (_, h) => [0, h - barChartHeight-macdHeight- annotationHeight];
    const chartHeight = gridHeight - macdHeight - annotationHeight;
    const macdChartOrigin = [0, gridHeight-macdHeight - annotationHeight];
    const barChartExtents = (data) => {
        return data.volume;
    };

    const candleChartExtents = (data) => {
        return [data.high, data.low];
    };


    const yEdgeIndicator = (data) => {
        return data.close;
    };

    const volumeColor = (data) => {
        return data.close > data.open
            ? "rgba(38, 166, 154, 0.3)"
            : "rgba(239, 83, 80, 0.3)";
    };

    const volumeSeries = (data) => {
        return data.volume;
    }; 

    const openCloseColor = (data) => {
        return data.close > data.open ? "#26a69a" : "#ef5350";
    };
    // code for anotations
/*     const [scale, setScale] = useState();
    const [adjustedAnnotations, setAdjustedAnnotations] = useState(null);
    useEffect(() => {
        if (annotations!=null){
            let r =adjustAnnotations(annotations);
            setAdjustedAnnotations(r.adjustedAnnotations);
            setScale(r.scale)
        }
      }, [annotations]);   */
    return (
        <ChartCanvas
            height={height}
            ratio={3}
            width={width}
            margin={margin}
            data={data}
            displayXAccessor={displayXAccessor}
            seriesName="Data"
            xScale={xScale}
            xAccessor={xAccessor}
            xExtents={xExtents}
            zoomAnchor={lastVisibleItemBasedZoomAnchor}
            
        >
            <Chart
                id={2}
                height={barChartHeight}
                origin={barChartOrigin}
                yExtents={barChartExtents}
            >
                <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries} />
            </Chart> 
            <Chart id={1} height={chartHeight} yExtents={candleChartExtents}>
                <GraphCandlestick/>
                <LineSeries yAccessor={ema1.accessor()} strokeStyle={ema1.stroke()} />
                <CurrentCoordinate
                    yAccessor={macd1.accessor()}
                    fillStyle={macd1.stroke()}
                />
                <MouseCoordinateY
                    rectWidth={margin.right}
                    displayFormat={pricesDisplayFormat}
                />
                <EdgeIndicator
                    itemType="last"
                    rectWidth={margin.right}
                    fill={openCloseColor}
                    lineStroke={openCloseColor}
                    displayFormat={pricesDisplayFormat}
                    yAccessor={yEdgeIndicator}
                />
                <MovingAverageTooltip
                    origin={[8, 24]}
                    textFill='var(--bs-body-color)'
                    options={[
                        {
                            yAccessor: ema1.accessor(),
                            type: "EMA",
                            stroke: ema1.stroke(),
                            windowSize: ema1.options().windowSize
                        }
                    ]}/>
               

            </Chart>
            
            <Chart id={4} yExtents={macd1.accessor()} height={macdHeight} origin={macdChartOrigin}>
                {/* {Code for the dates and the date tooltip} */}
                <MouseCoordinateX
                at="bottom"
                orient="bottom"
                displayFormat={(d) => d.toLocaleDateString()}/>
                <XAxis showGridLines gridLinesStrokeStyle="#e0e3eb" strokeStyle='var(--bs-body-color)' tickLabelFill='#949191' tickStrokeStyle="#949191"/>
                <YAxis  tickLabelFill='#949191' tickStrokeStyle="#949191" strokeStyle='var(--bs-body-color)'/>

                <MACDSeries yAccessor={macd1.accessor()} />

                <MACDTooltip origin={[8, 16]} 
                             yAccessor={macd1.accessor()} 
                             options={macd1.options()} 
                             appearance={macdAppearance}/>
                
            </Chart>
            {(annotations!=null) &&
            <Chart id={5} yExtents={[-0.5,0.5]} height={annotationHeight} origin={[0, gridHeight-annotationHeight]}>
                <GraphAnnotations annotations={annotations}></GraphAnnotations>
            </Chart>}


            <CrossHairCursor />
        </ChartCanvas>
    );
};
export default GraphMACDEMA;