
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";
const GraphNoData = ({id, line}) => {
    // hook for translation
    const { i18n, t } = useTranslation();
return(
    
    <Container fluid >
        <Row className='square border-bottom pt-1 pb-1'>
            <Col className='text-center h5'>
                {t('tablah.nodata')}
            </Col>
        </Row>
    </Container>
  );

};

export default GraphNoData;