import React from 'react';
import Container from 'react-bootstrap/Container';
import Graphline from './Graphline';
import InfiniteScroll from 'react-infinite-scroll-component';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";
import GraphNoData from './GraphNoData';
import GraphLoading from './GraphLoading';
import './Graphtable.css'
const Graphtable = ({table, totalLines, offset, fetchTable, mode}) => {
  // hook for translation
  const { i18n, t } = useTranslation();
return (
  <Container className='px-0'>


    <Row className='mt-5 mb-2 mx-1 d-none d-lg-flex overflowHidden'>
      <Col xs={2} lg={1}>
      </Col>
            <Col xs={8} lg={10}>
                <Container fluid >
                    <Row>
                        <Col lg={3} className='text-start h5 px-0'>
                        {t('tablah.company')}
                        </Col>
                        <Col lg={2} className='text-start h5 px-0'>
                        {t('tablah.strategy')}
                        </Col>
                        {mode==="todaySignals" && 
                        <Col lg={2} className='text-start h5 px-0'>
                        {t('tablah.position')}
                        </Col>}
                        {mode==="ranking" && 
                        <Col lg={2} className='text-start h5 px-0'>
                        {t('tablah.day_period')}
                        </Col>}
                        {mode==="todaySignals" && 
                        <Col lg={2} className='d-none d-lg-block text-start h5 px-0'>
                        {t('tablah.lastclosing')}
                        </Col>}
                        {mode==="ranking" && 
                        <Col lg={2} className='text-start h5 px-0'>
                        {t('tablah.profit')}
                        </Col>}
                        
                        <Col lg={3} className='d-none d-lg-block text-start h5 px-0'>
                        {t('tablah.sector')}
                        </Col>
                    </Row>
                </Container>
            </Col>
            <Col lg={1}>
           
            </Col>
    </Row>

  <InfiniteScroll
        dataLength={table.length}
        next={ ()=>{if (table.length>0){ /* we have to do this so when you have scrolled in the web and filter or reload the 
        first rows do not load twice, one for the call  in App when filtering and another when the table detects you are at the bottom */
                      fetchTable() 
                    }
                    }
        }
        hasMore={offset<totalLines} 
        loader={<GraphLoading></GraphLoading>}
        endMessage={<GraphNoData></GraphNoData>}
        className='px-0'
      >
          { table.map((line, index) => (
            <Graphline id = {index} key={index} line = {line}/>
          ))}
           </InfiniteScroll>
</Container>
  );

};

export default Graphtable;