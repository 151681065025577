import { useTranslation } from 'react-i18next';
import './AlertTopBarPromoCodePricing.css'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { host } from '../../utils/variablesProdTest';
import CloseButton from 'react-bootstrap/CloseButton';
const AlertTopBarPromoCodePricing = ({line, annotations, showInfo, setShowInfo}) => {
    const { i18n, t } = useTranslation();
    const [show, setShow] = useState(true);
return(
    <> 
        {show &&
        <div className="notification-top-bar d-flex align-items-center text-center">
            <p className='ms-auto'>
                    {t('pricing.promo_code')}
            </p>
            <CloseButton className="border-0 ms-3 ms-lg-auto me-3 " onClick={()=>(setShow(false))}></CloseButton>
        </div>
        } 
    </>
  );

};

export default AlertTopBarPromoCodePricing;