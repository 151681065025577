import { wikiEn } from "./wikiEn";
import { wikiHeadersEn } from "./wikiHeadersEn";
export const translationEn = 
{
title:'hello',
tablah : {
    profit:'Profit',
    day_period:'From',
    lastclosing : 'Last Closing',
    exchange : 'Exchange',
    indexes:'Index',
    position : 'Position',
    volume: 'Volume',
    country: 'Country',
    strategy: 'Strategy',
    company: 'Company',
    graph:'Graph',
    sector:'Sector',
    industry:'Industry',
    website:'Website',
    nodata:'No signals left - change filters or come back tomorrow for new opportunities!',
    need_premium_to_see:'With the Premium subscription you would have seen this signal',
    need_pro_to_see:'With the Pro subscription you would have seen this signal'
},
tradeView:{
    notSupported:'Sorry, this market is currently not avaible in TradingView widget.',
},
kofiButton:{
    supportUs:'If you find AtomicStonks useful, please consider supporting us with a beer!',
},
update_user:'Updating user, you will be redirected automatically',
graphinfo:{
    date:'Date',
    dateOpen:'Still Open',
    dateEntry:'Entry Date',
    dateExit:'Exit Date',
    price:'Price',
    exitReason:'Exit Reason',
    entry:'Entry',
    exit:'Exit',
    opTable:{
        'cross_inverse_band': 'The price crossed the opposite band and came back inside the bands',
        'cross_ma_25':'The price crossed the middle band and crossed it again reversely',
        'end':'The operation is still open',
        'inverse_entry':'Opposite position signal triggered',
        'macd_cross':'MACD line crossed the signal line in the opposite direction of the entry signal',
        'reverse_intersection':'MA6 crossed MA70 in the opposite direction of the entry signal',
        'rsi_cross':'RSI crossed the 50 line and crossed it again reversely',
        'sar_trend_change':'Trend reversed by the Parabolic SAR',
        'stochastic_cross':'SO crossed the 50 line and crossed it again reversely',
        'stoploss':'Stop Loss. Exit after losing 2% of the extreme point'
    },
    unlockWithPremium:'Unlock fundamental data with Premium, get a subscription',
    gross_profit:'Gross Profit',
    revenue:'Revenue',
    operating_income:'Operating Income',
    year_change:'Last Year Change',
    disclaimer:"Disclaimer: We do our best to ensure that the data we provide is relevant, accurate and reliable. But the sources we use are from third parties, so we cannot guarantee their reliability and we strongly recommend verifying the information.",
    pl:{
        title:'P&L',
        'Total Revenue': 'Total Revenue',
        'Cost Of Revenue':'COGS',
        'Gross Profit':'Gross Profit',
        'Operating Expense':'Operating Expense',
        'Operating Income':'Operating Income',
        'EBITDA':'EBITDA',
        'Interest Expense':'Financial Expense',
        'Net Income':'Net Income',
    },
    bs:{
        title:'Balance Sheet',
        'Current Assets':'Current Assets',
        'Cash And Cash Equivalents':'Cash And Cash Equivalents',
        'Accounts Receivable':'Accounts Receivable',
        'Inventory':'Inventory',
        'Total Non Current Assets':'Total Non Current Assets',
        'Total Assets':'Total Assets',
        'Current Liabilities':'Current Liabilities',
        'Accounts Payable':'Accounts Payable',
        'Total Non Current Liabilities Net Minority Interest':'Total Non Current Liabilities',
        'Total Liabilities Net Minority Interest':'Total Liabilities',
        'Capital Stock':'Capital Stock',
        'Retained Earnings':'Retained Earnings',  
        'Total Equity Gross Minority Interest':'Total Equity',
    },
    cf:{
        title:'Cash Flow',
        'Net Income':'Net Income',
        'Operating Cash Flow':'Operating Cash Flow',
        'Investing Cash Flow':'Investing Cash Flow',
        'Financing Cash Flow':'Financing Cash Flow',
        'Repayment Of Debt':'Repayment Of Debt',
        'Depreciation And Amortization':'D&A',
        'Issuance Of Debt':'Issuance Of Debt',
        'Capital Expenditure':'Capital Expenditure',
        'Cash Dividends Paid':'Cash Dividends Paid',
        'Free Cash Flow':'Free Cash Flow',
    }
},
header : {
    ranking:'BackTesting',
    todaysignals:'Today Signals',
    wiki:'Wiki',
    aboutus:'About Us'
},
footer:{
    description:'Our goal is to give accurate and relevant information while having fun.',
    followus:'Discover your market opportunity',
    followus2:'Follow us',
    aboutus:'More about us'
},
offcanvas:{
    filter:'Filter',
    filter_button:'Filter',
    filter_select_all:'Select All',
    filter_getWithPremium:'Unlock with Premium subscription',
    filter_getWithPro:'Unlock with Pro subscription',
    settings:{
        settings:'Settings',
        candlestick: 'Candlestick Color',
        candlestick_settingsBlackWhite: 'Black and White',
        candlestick_settingsRedGreen: 'Red and Green',
        theme: 'Theme',
        theme_dark:'Dark',
        theme_light:'Light'
    },
    profile:{
        profile:'Profile',
        name:'Name',
        email:'Email',
        logout:'Log Out',
        manage_subscription: 'Manage your Subscription',
        active_subscription:'Subscription',
        get_subscription:'Get a Subscription',
        free:'Free',
        premium:'Premium',
        pro:'Pro',
    }
},
landing:{
    login:'Log In', 
    title:'Invest smarter',
    title2:' with AtomicStonks',
    subtitle6:'Stop wasting time ',
    subtitle61:'analyzing countless charts hoping to find the perfect investment opportunity. Our tool automatically calculates the signals, ',
    subtitle62: 'you choose ',
    subtitle63: 'and deepen your analysis.',
    subtitle1:'We analyze over 1700 companies daily, creating a daily feed of short and long entry signals for 5 of the main technical strategies.',
    subtitle3:'Stop wasting time',
    subtitle31:' scanning through countless graphs.',
	subtitle4:'Focus on choosing',
    subtitle41:' the perfect opportunity.',
	subtitle5:'Deepen your analysis',
    subtitle51:' with our tools.',
    howitworks1:'AtomicStonks calculates the signals, ',
    howitworks2:'you choose',
    signalExpIntro1:'The ',
    signalExpIntro2:' section shows you all the signals triggered today, so you can focus on choosing the right one.',
    signalExpIntro3:'Here we have a ',
    signalExpIntro4:' signal of the strategy ',
    signalExpIntro5:' indicating it could be a good idea to buy Disney.',
    signalExp1:'Understand and study each company easily to see if it’s a fit for you.',
    signalExp1_1:'Access the company information with ',
    signalExp2:'and the graph with ',
    signalExp3:'Complete your analysis with TradingView using ',
    signalExp4:'That simple.',
    backTestingTitle1:'Process all the information ',
    backTestingTitle2:'Do the signals work?',
    backTesting1:'The ',
    backTesting2:' section will show you how the signals shown days ago have performed, by following the strategy.',
    signalButton:'Join us!',
    features1:'+ 1700 Companies',
    features11:'Every day we check if the strategies are fulfilled for more than 1700 companies from all over the world, providing you the signals.',
    features2:'Transparency, our priority',
    features22:'The ',
    features23:' section contains all the information about the indicators and strategies we use, so anyone can review our information.',
    features3:'30 indexes of 5 continents',
    features33:'We analyze 30 indexes from 28 different countries to gain global market and economic insights. All are major indexes like the S&P 500, NASDAQ 100, DAX 30, CAC 40, IBEX 35, and Nikkei 225, among many others.',
    video:'https://atomicstonks-public.s3.eu-north-1.amazonaws.com/landing/landingEN.mp4'
},
subscribeOnScreen:'Subscribe!',
alertTopBar :{
    signalsGenerated:' signals genertaded today ',
    subscribe:'subscribe',
    toUnlock:' to unlock them all.',
},
pricing:{
    promo_code:'Use the promotional code ATOMIC50 to get 50% off your first month. Available for a limited time.',
    button_monthly:'Monthly',
    button_yearly:'Annual',
    title:'Our plans ',
    title2:'for you!',
    subtitle:'For a limited time, enjoy the first month of AtomicStonks Premium and Pro ',
    subtitle2:'50% off with the code ATOMIC50.',
    itsFree:"It's Free",
    thisIncludes:'This includes:',
    free_title:'Free',
    free_button:'Sing In',
    row1:'9 global indexes',
    row2:'+500 companies analyzed every day',
    row3:'4 strategies:',
    row3_1:'SMA intersection',
    row3_2:'Parabolic Sar + MACD',
    row3_3:'Bollinger Bands + RSI',
    row3_4:'Stochastic Oscillator + RSI',
    row4:'All backtesting for the signals generated 7, 30, 90 and 180 previously',
    premium_title:'Premium',
    premium_badge:'Most Popular',
    premium_row1:'21 global indexes',
    premium_row2:'+1450 companies analyzed every day',
    premium_row3:'5 strategies:',
    premium_row3_1:'SMA intersection',
    premium_row3_2:'Parabolic Sar + MACD',
    premium_row3_3:'Bollinger Bands + RSI',
    premium_row3_4:'Stochastic Oscillator + RSI',
    premium_row3_5:'MACD + EMA',
    premium_row4:'All backtesting for the signals generated 7, 30, 90 and 180 previously',
    premium_row5:'Financials and metadata of the company',
    premium_row6:'TradingView toggle (if exists)',
    pro_title:'Pro',
    pro_row1:'30 global indexes',
    pro_row2:'+1700 companies analyzed every day',
    pro_row3:'5 strategies:',
    pro_row3_1:'SMA intersection',
    pro_row3_2:'Parabolic Sar + MACD',
    pro_row3_3:'Bollinger Bands + RSI',
    pro_row3_4:'Stochastic Oscillator + RSI',
    pro_row3_5:'MACD + EMA',
    pro_row4:'All backtesting for the signals generated 7, 30, 90 and 180 previously',
    pro_row5:'Financials and metadata of the company',
    pro_row6:'TradingView toggle (if exists)',
    subscribe:'Subscribe',
    saving1:'You save ',
    saving2:' yearly',
    days_free:'Try 15 days free',
    region:'Region',
    country:'Country',
    index:'Index'
},
ranking:{
    topWinners: 'Top Winners',
    topLosers: 'Top Losers',
    carousel1: 'Top ',
    carousel2Winners: ' Winners',
    carousel2Losers: ' Losers',
    carouselNoLosers: 'No Losers for this category',
    carouselNoWinners: 'No Winners for this category',
},
aboutus:{
    title:"About us",
    //intro:"We're two friends from college. We met at the Universitat Politècnica de València, where we both studied Telecommunications Engineering and Business Administration and Management. After finishing our studies, we were lucky enough to both move to Barcelona and become flatmates. We've been building this project while juggling personal life, studies, and work.",
    intro:'The AtomicStonks team.',
    nameMarc:"Marc Nieto Aliques",
    nameMartin:"Martín Guimerà Castell",
    nameEstefania:"Estefanía Galvan",
    infoMarc:"Hello! I'm Marc co-founder of AtomicStonks. I'm passionate about data, programming and lifting iron. My goal is to constantly move forward, create and have fun along the way. I've worked as a research engineer and I've been a data analyst in a bank for the last three years.",
    infoMartin:"Hey! I'm Martín, co-founder of AtomicStonks. I've always been fascinated by everything related to data and analysis. For the past three years, I've been working with a rapidly growing start-up, spending one and a half of those years on the Analytics team.",
    infoEstefania:"Hi! I'm Estefanía, co-founder of AtomicStonks. For the last three years, I've been working in the Fintech space as Digital Marketing Manager for both fast-growing startups and scaleups. I'm passionate about boosting my creativity through all kinds of crafts and building brands.",
    outtro:"As you can see we love what we're doing and hopefully it will make some money while helping people to take better investment decisions.",
    contactus1:"Don't hesitate to DM or email us at ",
    contactus2:". Your feedback is invaluable in helping us enhance our platform and develop something truly useful.",
    linkedin:"See my Linkedin"
},
filterGraph:{
    generated1:"Selected",
    generated2: "signals from",
    generated3: "generated today",
    nodata: "Selection not valid",
},
singInModal:{
    singin:"Log in to continue, you're almost there!",
    policy1: "By Logging In you accept our ",
    privacy_policy:"privacy policy",
    policy2:" and our ",
    terms_conditions:"terms and conditions."
},
data:{
    day_period:{
        "30 days":"30 days ago",
        "90 days":"90 days ago",
        "180 days":"180 days ago",
        "7 days":"7 days ago",
    },
    strategy:{
        bollinger_rsi: 'Bollinger + RSI',
        moving_average_intersection: 'SMA Intersection',
        macd_ema:'EMA + MACD',
        sar_macd:'SAR + MACD',
        stochastic_rsi: 'Stochastic Oscillator + RSI'
    },
    position:{
        short:'Short',
        long:'Long'
    },
    country:{
        ARG:"Argentina",
        AUS:"Australia",
        BEL:"Belgium",
        BRA:"Brazil",
        CAN:"Canada",
        CHE:"Switzerland",
        CHL:"Chile",
        DEU:"Germany",
        DNK:"Denmark",
        ESP:"Spain",
        FIN:"Finland",
        FRA:"France",
        GBR:"United Kingdom",
        HKG:"Hong Kong",
        IND:"India",
        IRL:"Ireland",
        ITA:"Italy",
        JPN:"Japan",
        KOR:"South Korea",
        MEX:"Mexico",
        NLD:"Netherlands",
        NOR:"Norway",
        PRT:"Portugal",
        SGP:"Singapore",
        SWE:"Sweden",
        USA:"United States",
        ZAF:"South Africa"
    },
    sector:{
        basic_materials:"Basic Materials",
        communication_services:"Communication Services",
        consumer_cyclical:"Consumer Cyclical",
        consumer_defensive:"Consumer Defensive",
        energy:"Energy",
        financial_services:"Financial Service",
        healthcare:"Healthcare",
        industrial_goods:"Industrial Goods",
        industrials:"Industrials",
        real_estate:"Real Estate",
        technology:"Technology",
        utilities:"Utilities"
    },
    industry: {
        advertising_agencies:"Advertising Agencies",
        aerospace_defense:"Aerospace & Defense",
        agricultural_inputs:"Agriculture",
        airlinesairports_air_services:"Airlines, Airports & Air Services",
        asset_management:"Asset Management",
        auto:"Automobile",
        banks:"Banks",
        beverages_alcoholic:"Beverages Alcoholic",
        beverages_nonalcoholic:"Beverages Non-Alcoholic",
        biotechnology:"Biotechnology",
        building_materials:"Building Materials",
        building_products_equipment:"Building Products & Equipment",
        business_equipment_supplies:"Business Equipment & Supplies",
        capital_markets:"Capital Markets",
        chemicals:"Chemicals",
        clothing_footwear_accessories:"Clothing, Footwear & Accessories",
        communication_equipment:"Communication Equipment",
        computer_hardware:"Computer Hardware",
        conglomerates:"Conglomerates",
        consumer_electronics:"Consumer Electronics",
        credit_services:"Credit Services",
        data_analytics:"Data Analytics",
        department_stores:"Department Stores",
        diagnostics_research:"Diagnostics & Research",
        discount_stores:"Discount Stores",
        drugs:"Drugs",
        education_training_services:"Education & Training Services",
        electrical_equipment_parts:"Electrical Equipment & Parts",
        electronic_components:"Electronic Components",
        engineering_construction:"Engineering & Construction",
        entertainment:"Entertainment",
        farm_products:"Farm Products",
        financial_conglomerates:"Financial Conglomerates",
        financial_data_stock_exchanges:"Financial Data & Stock Exchanges",
        food_food_distribution:"Food & Food Distribution",
        food_food_distribution_confectioners:"Food, Food Distribution & Confectioners",
        furnishings_fixtures_appliances:"Furnishings, Fixtures & Appliances",
        gambling_casinos_resorts:"Gambling, Casinos & Resorts",
        gold_copper:"Gold & Copper",
        grocery_stores:"Grocery Stores",
        health_information_services:"Health Information Services",
        healthcare_plans:"Healthcare Plans",
        hotels_lodging:"Hotels & Lodging",
        household_personal_products:"Household & Personal Products",
        industrial_distribution:"Industrial Distribution",
        information_technology_services:"Information Technology Services",
        infrastructure_operations:"Infrastructure Operations",
        insurance:"Insurance",
        integrated_freight_logistics:"Integrated Freight & Logistics",
        internet_content_information:"Internet Content & Information",
        internet_retail:"Internet Retail",
        leisure:"Leisure",
        luxury_goods:"Luxury Goods",
        machinery__industrial_construction_farm_speciality:"Machinery - Industrial, Construction, Farm, & Speciality",
        marine_shipping:"Marine Shipping",
        medical_care_facilities:"Medical Care Facilities",
        medical_devices:"Medical Devices",
        medical_distribution:"Medical Distribution",
        medical_instruments_supplies:"Medical Instruments & Supplies",
        mining:"Mining",
        oil_gas:"Oil & Gas",
        packaging_containers:"Packaging & Containers",
        paper_wood_production:"Paper & Wood Production",
        personal_services:"Personal Services",
        pharmaceutical_retailers:"Pharmaceutical Retailers",
        publishing:"Publishing",
        railroads:"Railroads",
        real_estate:"Real Estate",
        reit_B2B:"Reit B2B",
        rental_leasing_services:"Rental & Leasing Services",
        residential_construction:"Residential Construction",
        restaurants:"Restaurants",
        retail:"Retail",
        scientific_technical_instruments:"Scientific & Technical Instruments",
        security_protection_services:"Security & Protection Services",
        semiconductors:"Semiconductors",
        software:"Software",
        solar:"Solar",
        specialty_business_services:"Specialty Business Services",
        staffing_employment_services:"Staffing & Employment Services",
        steel_aluminum:"Steel & Aluminum",
        telecom_services:"Telecom Services",
        textile_manufacturing:"Textile Manufacturing",
        thermal_coal:"Thermal Coal",
        tobacco:"Tobacco",
        tools_accessories:"Tools & Accessories",
        travel_services:"Travel Services",
        trucking:"Trucking",
        uranium:"Uranium",
        utilities_diversified:"Utilities Diversified",
        utilities_independent_power_producers:"Utilities Independent Power Producers",
        utilities_regulated_energy:"Utilities Regulated Energy",
        utilities_regulated_water:"Utilities Regulated Water",
        utilities_renewable:"Utilities Renewable",
        waste_management_pollution_treatment_controls:"Waste Management, Pollution & Treatment Controls"
    },
    indexes:{
        AEX25:"AEX 25",
        ASX50:"ASX 50",
        BEL20:"BEL 20",
        BMVIPC:"BMV IPC",
        CAC40:"CAC 40",
        CLXIPSA:"CLX IPSA",
        DAX40:"DAX 40",
        DOWJONES:"Dow Jones",
        EUROSTOXX50:"EURO STOXX 50",
        FTSE100:"FTSE 100",
        FTSEMIB:"FTSE MIB",
        HSI50:"HSI 50",
        IBEX35:"IBEX 35",
        IBOVESPA:"IBOVESPA",
        ISEQ20:"ISEQ 20",
        KOSPI:"KOSPI",
        MERVAL:"MERVAL",
        NASDAQ100:"NASDAQ 100",
        NIFTY50:"NIFTY 50",
        NIKKEI225:"NIKKEI 225",
        OBXO25:"OBX Oslo 25",
        OMXC25:"OMX Copenhage 25",
        OMXH25:"OMX Helsinki 25",
        OMXS30:"OMX Stockholm 30",
        PSI20:"PSI 20",
        SA40:"SA 40",
        SMI:"SMI ",
        SP500:"S&P 500",
        STI:"STI ",
        TSX60:"TSX 60"
    },
    exchange: {
        AMS:"Euronext Amsterdam",
        ASX:"Australian Securities Exchange",
        BCBA:"Buenos Aires Stock Exchange",
        BIT:"Borsa Italiana",
        BME:"Spanish Stock Exchanges and Markets",
        BMV:"Bolsa Mexicana de Valores",
        BVMF:"B3 – Brasil, Bolsa, Balcão",
        CPH:"Nasdaq Copenhagen",
        DWX:"SIX Swiss Exchange",
        EBR:"Euronext Brussels",
        ELI:"Euronext Lisbon",
        EPA:"Euronext Paris",
        ETR:"Frankfurt Stock Exchange",
        HEL:"Nasdaq Helsinki",
        HKG:"Stock Exchange of Hong Kong",
        ISE:"Euronext Dublin",
        JSA:"Johannesburg Stock Exchange",
        KRX:"Korea Exchange",
        LSE:"London Stock Exchange",
        NASDAQ:"Nasdaq",
        NSE:"National Stock Exchange of India",
        NYSE:"New York Stock Exchange",
        OSL:"Oslo Stock Exchange",
        SGX:"Singapore Exchange",
        SSE:"Santiago Stock Exchange",
        STO:"Nasdaq Stockholm",
        TSE:"Toronto Stock Exchange",
        TYO:"Tokyo Stock Exchange"
    },
    continent:{
        EEE:"Europe",
        EU:"European Union",
        UUU:"Oceania",
        SRR:"South America",
        NNN:"North America",
        FFF:"Africa",
        ABB:"Asia"
    }
}, 
wiki: wikiEn, 
wikiHeaders:wikiHeadersEn,
};