import React, { useState, useEffect, useRef } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import GraphInfo from './GraphInfo';
import GraphMovingAverageIntersection from '../graph_components/GraphMovingAverageIntersection';
import GraphBollingerRSI from '../graph_components/GraphBollingerRSI';
import GraphMACDEMA from '../graph_components/GraphMACDEMA';
import GraphStochasticRSI from '../graph_components/GraphStochasticRSI';
import GraphSARMACD from '../graph_components/GraphSARMACD';
import TableOperationsRanking from '../ranking/TableOperationsRanking';
import noLogo from '../../assets/no_logo.png';

import { Badge, Col, Container, Row, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {TbInfoCircle,TbEdit} from 'react-icons/tb';
import {PiChartLineUpBold} from 'react-icons/pi';
import { FaListOl } from "react-icons/fa";
import TradingViewWidget from '../graph_components/TradingViewWidget';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { positionColor } from '../../utils/colors';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './Graphline.css'
const Graphline = ({id, line, className}) => {
    // hook for translation
    const { i18n, t } = useTranslation();
    const [openGraph, setOpenGraph] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [graph_width, setGraph_width] = useState(300);
     // code for getting the user from redux
    const user = useSelector((state) => state.user.user);
    const graph = JSON.parse(line.graph); //we have to parse it because its a string
    // this allows to pass the width of the ref compoonent to the graph so it knows how wide to be
    const ref = useRef(null);
    useEffect(() => {
        if (!ref.current) return;
        const resizeObserver = new ResizeObserver(() => {
            setGraph_width(ref.current ? ref.current.offsetWidth: 300);
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect(); // clean up 
      }, []);
    const positionItem = () =>   {
        let color ='';
        let text ='';
        if(line.position===-1){
            color = positionColor.short;
            text = 'short'
        }else{
            color = positionColor.long;
            text = 'long'
        }
        return( <div className={'text-white text-center fw-bold px-2 rounded'} style={{'backgroundColor':color}}>
                    {t('data.position.'+text)}
                </div>
                );
 
        
    }
    // function to detect error on image and cahnge to no logo
    const addDefaultImg = (e) =>   {
        e.target.src = noLogo;
    }
    // this decides wich graph to display using differents strategies
    const showGraph = ()=>{
        // if annotations does not exist we create it to null so the graphs can handle it
        if (!('annotations' in graph)) {
            graph['annotations'] = null;
          } 
          switch (line.strategy) {
            case 'moving_average_intersection':
                return (
                    <GraphMovingAverageIntersection
                        strategy={line.strategy}
                        stock_data={graph.data}
                        params={graph.params}
                        graph_width={graph_width}
                        annotations={graph.annotations}
                    />
                );
            case 'bollinger_rsi':
                return (
                    <GraphBollingerRSI
                        strategy={line.strategy}
                        stock_data={graph.data}
                        params={graph.params}
                        graph_width={graph_width}
                        annotations={graph.annotations}
                    />
                );
            case 'macd_ema':
                return (
                    <GraphMACDEMA
                        strategy={line.strategy}
                        stock_data={graph.data}
                        params={graph.params}
                        graph_width={graph_width}
                        annotations={graph.annotations}
                    />
                );
            case 'sar_macd':
                return (
                    <GraphSARMACD
                        strategy={line.strategy}
                        stock_data={graph.data}
                        params={graph.params}
                        graph_width={graph_width}
                        annotations={graph.annotations}
                    />
                );
            case 'stochastic_rsi':
                return (
                    <GraphStochasticRSI
                        strategy={line.strategy}
                        stock_data={graph.data}
                        params={graph.params}
                        graph_width={graph_width}
                        annotations={graph.annotations}
                    />
                );
            default:
                return <div>Error Strategy not drawable</div>;
        }
    }
    // this is the number formatter
    const userLanguage = navigator.language || navigator.userLanguage;
    const formatter = new Intl.NumberFormat(userLanguage, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

    // This code is to handle the trading view widget 
    const [showTradingView, setShowTradingView] = useState(false);
    const calculateExchange = ()=>{
        let s = line.ticker.split(".")[1]
        if (s==='DE'){
          return 'XETR';
        }else if (s==null){
          return ' ';
        }else {
          return null;
        }
      };
    const exchange = calculateExchange();

    // This code is to chandle the operations table from ranking when is neded
    //this is for openning operation details of ranking
    const [showOperationsTable, setShowOperationsTable] = useState(false);
    const convertArray = (str)=>{
        if(user){
            str = str.substring(1,  str.length - 1);
            // Split the string by commas to get an array
            return str.split(",");
        }
       return null
    }
    const tiers = convertArray(line.tier);
    const currentPath = window.location.pathname;
return(
    
    <Container fluid >
        <Row ref = {ref} className='square border-bottom pt-1 pb-1'>
            <Col xs={2} lg={1} className = 'd-flex flex-column flex-lg-row align-items-center text-center text-lg-start'>
            <img className= "rounded-circle shadow-sm bg-light" style={{objectFit:"contain"}} width="50" height="50" src={"https://logo.clearbit.com/"+line.website} onError={addDefaultImg} loading="lazy"></img>
            {user && currentPath==='/ranking' &&
                <div >
                {user.subscription==='free' && 
                    <div>
                        {!tiers.includes('free') && tiers.includes('premium') && 
                            <OverlayTrigger  placement="top" delay={{ show: 100, hide: 1000 }}
                            overlay={(props) => (
                            <Tooltip id="button-tooltip"
                                {...props}
                            >
                                {t('tablah.need_premium_to_see')}
                            </Tooltip>
                            )}
                            >
                                <Badge className='badge-premium'>{t('pricing.premium_title')}</Badge>
                            </OverlayTrigger>
                        }   
                        {!tiers.includes('free') && tiers.includes('pro') && 
                            <OverlayTrigger  placement="top" delay={{ show: 100, hide: 1000 }}
                            overlay={(props) => (
                            <Tooltip id="button-tooltip"
                                {...props}
                            >
                                {t('tablah.need_pro_to_see')}
                            </Tooltip>
                            )}
                            >
                                <Badge  className='badge-pro'>{t('pricing.pro_title')}</Badge>
                            </OverlayTrigger>
                        }
                    </div>
                }
                {user.subscription==='premium' && !tiers.includes('premium') && <>
                            <OverlayTrigger  placement="top" delay={{ show: 100, hide: 1000 }}
                            overlay={(props) => (
                            <Tooltip id="button-tooltip"
                                {...props}
                            >
                                {t('tablah.need_pro_to_see')}
                            </Tooltip>
                            )}
                            >
                            <Badge  className='badge-pro'>{t('pricing.pro_title')}</Badge>
                            </OverlayTrigger>
                        </>
                }
                
                </div>
            }
            </Col>
            <Col xs={8} lg={10}>
                <Container fluid >
                    <Row>
                        <Col xs={12} lg={3} className='align-self-center'>
                        <h6 className='mb-0'>{line.companyname}</h6>
                        <small >{line.ticker}</small>
                        </Col>
                        <Col xs={12} lg={2} className='align-self-center fw-bold'>
                        <small><Link to={'/wiki/'+line.strategy}>{t('data.strategy.'+line.strategy)}</Link></small>
                        </Col>
                        {line.position && <Col xs={12} lg={2} className='align-self-center d-flex'>
                        {positionItem()}
                        </Col>}
                        {line.day_period && <Col xs={12} lg={2} className='align-self-center d-flex'>
                        {t('data.day_period.'+line.day_period)}
                        </Col>}
                        {line.position && <Col xs={12} lg={2} className='d-none d-lg-block align-self-center'>
                        {formatter.format(line.close)} <small>{line.currency}</small>
                        </Col>}
                        {line.day_period && <Col xs={12} lg={2} className={'align-self-center d-flex fw-bolder fs-5 ' + (line.total_profitability > 0 ? 'text-success' : 'text-danger')}>
                        {(line.total_profitability > 0 ? '+' : '' )+formatter.format(line.total_profitability*100)+'%'}
                        </Col>}
                        <Col lg={3} className='d-none d-lg-block align-self-center'>
                        {t('data.sector.'+line.sector)}
                        </Col>
                    </Row>
                </Container>
            </Col>
            <Col xs={2} lg={1} className = 'd-block d-lg-flex align-items-center mx-0 '>
            <Button id="show_info_button" type ="Button" variant="outline-secondary" className={"me-2 p-1 mt-lg-0 mt-2 mb-lg-0 mb-2 " +className} onClick={() => setShowInfo(!showInfo)}>
               <TbInfoCircle id="show_info_button" className='text-primary h4 m-0'  style={{ pointerEvents: 'none'}}></TbInfoCircle>
            </Button>
            <Button id = "show_graph_button" type ="Button" variant="outline-secondary" className={"btn p-1 "+className} onClick={() => setOpenGraph(!openGraph)}>
                <PiChartLineUpBold  className='text-primary h4 m-0' style={{ pointerEvents: 'none'}}></PiChartLineUpBold>
            </Button>
            </Col>
        </Row>
        <Collapse in = {openGraph} className='square border-bottom' mountOnEnter={true} unmountOnExit={true}>
            <div>
                <Container className='p-0 text-start'>
                    <Row>
                    <Col xs={9} lg={11}>
                    </Col>
                    <Col xs={3} lg={1} className = 'd-flex align-items-center mx-0'>
                        
                        {graph.annotations ?
                        <Button id ="show_operations_button" type ="Button" variant="outline-secondary" className="me-2 p-1 mt-1 mb-lg-0" onClick={() => setShowOperationsTable(!showOperationsTable)}>
                            <FaListOl  id = "show_operations_button" className='text-primary h4 m-0'  style={{ pointerEvents: 'none'}}></FaListOl>
                        </Button>
                        :
                        <div style={{width:'33px'}}  className="me-2 p-1 mt-1 mb-lg-0"></div>
                        }
                        {exchange 
                        ? 
                        <Button id = "show_tradingview_button" type ="Button" variant="outline-secondary" className={"btn p-1 mt-1 me-2 "+className} onClick={()=>setShowTradingView(!showTradingView)}>
                            <TbEdit id = "show_tradingview_button" className='text-primary h4 m-0'  style={{ pointerEvents: 'none'}}></TbEdit>
                        </Button>
                        :
                        <OverlayTrigger  placement="left" delay={{ show: 100, hide: 1000 }}
                            overlay={(props) => (
                            <Tooltip id="button-tooltip"
                                {...props}
                            >
                                {t('tradeView.notSupported')}
                            </Tooltip>
                            )}
                        >
                            <Button  id = "show_tradingview_button_no_view" type ="Button" variant="outline-secondary" className="btn p-1 mt-1 me-2">
                                <TbEdit id = "show_tradingview_button_no_view" className='text-primary h4 m-0'  style={{ pointerEvents: 'none'}}></TbEdit>
                            </Button>
                        </OverlayTrigger>
                        }
                    </Col>
                    </Row>
                </Container>
                
                {showGraph()}
                <TradingViewWidget symbol={line.ticker} exchange={exchange} showTradingView={showTradingView} setShowTradingView={setShowTradingView}></TradingViewWidget>
                <TableOperationsRanking line={line} annotations={graph.annotations} showOperationsTable={showOperationsTable}></TableOperationsRanking>
            </div>
        </Collapse>
        
        <GraphInfo line={line} annotations={graph.annotations} showInfo={showInfo} setShowInfo={setShowInfo}></GraphInfo>
    </Container>
  );

};

export default Graphline;